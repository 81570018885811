// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';

@Component({
	selector: 'kt-subheader',
	templateUrl: './subheader.component.html',
})
export class SubheaderComponent implements OnInit {
	// Public properties
	// subheader layout
	layout: string;
	fluid: boolean;
	clear: boolean;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService) {
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
	}

	url = ''

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();

		this.layout = objectPath.get(config, 'subheader.layout');
		this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
		this.clear = objectPath.get(config, 'subheader.clear');
	}

	isTarifando(){
		if(this.url=='tarifando'){
			return true
		}
	}

	isStandard(){
		if(this.url=='painel' || this.url=='notifiqueai' || window.location.href.includes('localhost')){
			return true
		}
	}
}
