import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {ApiJsService} from '../../../services/apiJS.service';
import {NotifyService} from '../../../services/notify.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as cep from 'cep-promise';
import { SocketService } from '../../../services/socket.service';
import { ClipboardService } from 'ngx-clipboard'
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../services/user.service';
import { HttpClient } from '@angular/common/http';

import * as constants from '../../../constants';
@Component({
	selector: 'kt-channels',
	templateUrl: './channels.component.html',
	styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {
	channels: any = [];
	loading: any = false;
	activeChannel: any = null;
	plans: any = [];
	queueClean: string;
	channel: any = [];
	queueCount: any = [];
	filters: any = {};
	loadingQueue: any =  false;
	loadingCreateContainer: any =  false;
	loadingRecreateContainer: any =  false;
	loadingRestartContainer: any =  false;
	operatorsChanged: any = false;
	instantAttendanceChanged: any = false;
	today: any = new Date().toISOString().split("T")[0];
	sentToday: any = ''
	sentMonth: any = ''
	contacts: any = ''
	loadingContacts: any = false;
	loadingNotifications: any = false;
	serverPick: any = 10;
	channelTypePick: any = 1;
	modalPickPriSearch: any = '';
	modalRedirectToSearch: any = '';
	secondaryChannel: any = {};
	departments: any = [];
	servers: any = [
		// {code:1,name:'Notifique'},
		// {code:2,name:'Tarifando'},
		// {code:10,name:'JsAPI'},
		// {code:11,name:'Desenvolvimento'},
	]
	sortCount: any = []

	newPlan: any;

	originalState: any = {};

	redirectChannel: any = {};
	redirectQuantity: any = 0;

	@ViewChild('modalChannel', {static: false}) modalChannel: ElementRef;
	@ViewChild('modalQueue', {static: false}) modalQueue: ElementRef;
	@ViewChild('modalClean', {static: false}) modalClean: ElementRef;
	@ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;
	@ViewChild('modalPickChannelType', {static: false}) modalPickChannelType: ElementRef;
	@ViewChild('modalPickServer', {static: false}) modalPickServer: ElementRef;
	@ViewChild('modalPickPrimaryChannel', {static: false}) modalPickPrimaryChannel: ElementRef;
	@ViewChild('modalCreateSecChannel', {static: false}) modalCreateSecChannel: ElementRef;
	@ViewChild('modalAddPlan', {static: false}) modalAddPlan: ElementRef;
	@ViewChild('modalPlanExtract', {static: false}) modalPlanExtract: ElementRef;
	@ViewChild('modalPlanFilter', {static: false}) modalPlanFilter: ElementRef;
	@ViewChild('modalRedirect', {static: false}) modalRedirect: ElementRef;
	@ViewChild('modalRedirectTo', {static: false}) modalRedirectTo: ElementRef;
	@ViewChild('modalContainer', {static: false}) modalContainer: ElementRef;

	private planExtractData: any = [];

	extractFilter: any = {
		month: '',
		day: '',
		start_date: '',
		end_date:'',
	}

	constructor(public ktDialogService: KtDialogService,
				public api: ApiService,
				public apiJS: ApiJsService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				private modalService: NgbModal,
				private socketService: SocketService,
				private clipboardService: ClipboardService,
				private dialog: MatDialog,
				private snackBar: MatSnackBar,
				public user: UserService,
				public http: HttpClient,
				) {
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')) {
			url = url.replace('https://','').split('.')[1]
		} else {
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
	}

	url = ''
	tokenUrl=''

	ngOnInit() {
		this.load();
		// this.plans = ['0','200','5000','10000','20000','30000','40000','50000','120000','Ilimitado']
		this.socketService.onSetDepartments().subscribe((data:any) => {
			this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			if(!this.departments.find(el=>el.name=='Nenhuma')) {
				this.departments.unshift({_id:'0',name:'Nenhuma'})
			}
		});
		this.socketService.onSetServerConfig().subscribe((data:any) => {
			console.log('servers',data)
			this.servers = data
		});
	}

	onCreate() {
		if(this.channelTypePick==1) {
			this.activeChannel = {
				id: null,
				login: '',
				new_password: '',
				whatsapp_name: '',
				whatsapp_id: '',
				enable_api: '1',
				enable_chatbot: '1',
				enable_batch_notifications: '0',
				company_name: '',
				company_description: '',
				company_phone: '',
				company_whatsapp: '',
				company_email: '',
				company_website: '',
				plano_notificacoes: 5000,
				server: this.serverPick,
			};
			this.modalService.open(this.modalChannel);
		} else {
			this.modalService.open(this.modalPickPrimaryChannel);
		}
	}

	private async load() {
		this.ktDialogService.show();
		this.loadingContacts = true
		this.loadingNotifications = true
		this.getTotalNotifications(this.filters)
		this.getTotalContacts(this.filters)
		this.getServers()
		// this.plans = await this.apiJS.get_plans()
		this.plans = await this.api.get_plans()
		if(!this.plans.find(el=>el.id==0)) {
			this.plans.push({id:0,value:'Adicionar'})
		}
		let channels = await this.api.get_channels(0,this.filters)
		// let channels = await this.apiJS.get_channels(0,this.filters)
		this.channels = (channels).map(channel => {
			channel.status_description = 'offline';
			channel.status_class = 'danger';

			try {
				channel.criado_em = new Date(channel.criado_em).toISOString().slice(0, 10);
			} catch (error) {
				print();
			}

			if (channel.status === 'authenticated') {
				channel.status_description = 'online';
				channel.status_class = 'success';
			}
			return channel;
		});
		this.setTokenUrl()
		this.ktDialogService.hide();
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
		this.channels.forEach(async (channel)=>{
			let queue = await this.api.queueTotal(channel.instance_id)
			channel.queue_length = queue
			if (!this.ref['destroyed']) {
				this.ref.detectChanges();
			}
			let sent = await this.api.sentThisMonth(channel.instance_id)
			channel.sent_qty = sent
			if (!this.ref['destroyed']) {
				this.ref.detectChanges();
			}
		})
	}

	async onSave() {
		if(!this.activeChannel.whatsapp_name || this.activeChannel.whatsapp_name==0) {
			return this.notify.show('Campo Nome Vazio!', 'danger');
		}
		if(!this.activeChannel.whatsapp_id || this.activeChannel.whatsapp_id==0) {
			return this.notify.show('Campo Número do WhatsApp Vazio!', 'danger');
		}

		if(!this.activeChannel.main_channel_id) {
			if(!this.activeChannel.login || this.activeChannel.login==0) {
				return this.notify.show('Campo E-mail de Login Vazio!', 'danger');
			}
			if(!this.activeChannel.company_name || this.activeChannel.company_name==0) {
				return this.notify.show('Campo Razão Social Vazio!', 'danger');
			}

			if(!this.activeChannel.company_cnpj || this.activeChannel.company_cnpj==0) {
				return this.notify.show('Campo CNPJ ou CPF Vazio!', 'danger');
			}
			if(!this.activeChannel.zipcode || this.activeChannel.zipcode==0) {
				return this.notify.show('Campo CEP Vazio!', 'danger');
			}
			if(!this.activeChannel.company_phone || this.activeChannel.company_phone==0) {
				return this.notify.show('Campo Telefone Vazio!', 'danger');
			}
		}

		this.ktDialogService.show();
		var newChannel = false
		if(this.activeChannel.instantAttendance) {
			this.activeChannel.enable_operators = '1'
		}
		await this.api.admin_channel_save(this.activeChannel).then( async value => {
			if (value.success) {
				// console.log(this.activeChannel.validateContact)
				if(this.activeChannel.validateContact && this.activeChannel.validateContact!=0) {
					this.activeChannel.validateContact = true
				} else {
					this.activeChannel.validateContact = false
				}
				if(!this.activeChannel.id) {
					newChannel = true
					this.activeChannel.id = value.user.id
					this.activeChannel.instance_id = value.user.id
					this.activeChannel.token = value.user.token
					this.activeChannel.status = 'got qr code'
				}
				if(this.operatorsChanged) {
					if(this.activeChannel.enable_operators=='1') {
						let bot = await this.createBot()
						if(bot['error']) {
							console.log('bot: ',bot['message'])
							if(newChannel) {
								let resp = await this.apiJS.createChannelMongo(this.activeChannel)
								// if(!resp.statusCode.includes([200,201])) {
								// 	return this.notify.show("MongoDB: Ocorreu um erro", 'danger');
								// }
							} else {
								// this.socketService.updateDataChannel(this.activeChannel)
								await this.apiJS.updateChannelMongo(this.activeChannel)
								if(this.instantAttendanceChanged) {
									await this.apiJS.updateChannelConfigMongo({ channelId: this.activeChannel.instance_id || this.activeChannel.instanceId,  instantAttendance: this.activeChannel.instantAttendance })
								}
							}
							// return this.notify.show('Bot: '+bot['message'], 'danger')
						} else {
							if(newChannel) {
								let resp = await this.apiJS.createChannelMongo(this.activeChannel,bot)
								// if(!resp.statusCode.includes([200,201])) {
								// 	return this.notify.show("MongoDB: Ocorreu um erro", 'danger');
								// }
							} else {
								// this.socketService.updateDataChannel(this.activeChannel)
								await this.apiJS.updateChannelMongo(this.activeChannel)
								if(this.instantAttendanceChanged) {
									await this.apiJS.updateChannelConfigMongo({ channelId: this.activeChannel.instance_id || this.activeChannel.instanceId,  instantAttendance: this.activeChannel.instantAttendance })
								}
							}
						}
					} else {
						await this.deleteBot()
						this.activeChannel.operationalChannelEnabled = false
						if(newChannel) {
							let resp = await this.apiJS.createChannelMongo(this.activeChannel)
							// if(!resp.statusCode.includes([200,201])) {
							// 	return this.notify.show("MongoDB: Ocorreu um erro", 'danger');
							// }
						} else {
							// this.socketService.updateDataChannel(this.activeChannel)
							await this.apiJS.updateChannelMongo(this.activeChannel)
							await this.apiJS.updateChannelConfigMongo({ channelId: this.activeChannel.instance_id || this.activeChannel.instanceId, operationalChannelEnabled: 0})
						}
					}
				} else {
					if(newChannel) {
						let resp = await this.apiJS.createChannelMongo(this.activeChannel)
						// if(!resp.statusCode.includes([200,201])) {
						// 	return this.notify.show("MongoDB: Ocorreu um erro", 'danger');
						// }
					} else {
						// this.socketService.updateDataChannel(this.activeChannel)
						await this.apiJS.updateChannelMongo(this.activeChannel)
						if(this.instantAttendanceChanged) {
							await this.apiJS.updateChannelConfigMongo({ channelId: this.activeChannel.instance_id || this.activeChannel.instanceId,  instantAttendance: this.activeChannel.instantAttendance })
						}
					}
				}
				this.notify.show(value.message, 'success');
				this.modalService.dismissAll();
				this.load();
			}
			else {
				if(value.fail) {
					this.notify.show(`Campo ${value.fail} Inválido!`, 'danger');
				} else {
					this.notify.show(value.message, 'danger');
				}
			}
		});

		// await this.apiJS.admin_channel_save(this.activeChannel).then( async value => {
		// 	console.log('save js',value)
		// 	if (value.success) {
		// 		if(this.activeChannel.validateContact && this.activeChannel.validateContact!=0) {
		// 			this.activeChannel.validateContact = true
		// 		} else {
		// 			this.activeChannel.validateContact = false
		// 		}
		// 		if(!this.activeChannel.id) {
		// 			newChannel = true
		// 			this.activeChannel.id = value.user.id
		// 			this.activeChannel.instance_id = value.user.id
		// 			this.activeChannel.token = value.user.token
		// 		}
		// 		if(!this.activeChannel.status) {
		// 			this.activeChannel.status = 'got qr code'
		// 		}
		// 		if(this.operatorsChanged) {
		// 			if(this.activeChannel.enable_operators=='1') {
		// 				let bot = await this.createBot()
		// 				if(bot['error']) {
		// 					console.log('bot: ',bot['message'])
		// 					if(newChannel)
		// 						this.socketService.createChannel(this.activeChannel,null)
		// 					else
		// 						this.socketService.updateDataChannel(this.activeChannel)
		// 					// return this.notify.show('Bot: '+bot['message'], 'danger')
		// 				} else {
		// 					console.log('channel',this.activeChannel)
		// 					if(newChannel)
		// 						this.socketService.createChannel(this.activeChannel,bot)
		// 					else
		// 						this.socketService.updateDataChannel(this.activeChannel)
		// 				}
		// 			} else {
		// 				await this.deleteBot()
		// 			}
		// 		} else {
		// 			if(newChannel)
		// 				this.socketService.createChannel(this.activeChannel,null)
		// 			else
		// 				this.socketService.updateDataChannel(this.activeChannel)
		// 		}
		// 		this.notify.show(value.message, 'success');
		// 		this.modalService.dismissAll();
		// 		this.load();
		// 	}
		// 	else {
		// 		if(value.fail) {
		// 			this.notify.show(`Campo ${value.fail} Inválido!`, 'danger');
		// 		} else {
		// 			this.notify.show(value.message, 'danger');
		// 		}
		// 	}
		// })
		if(this.originalState && this.activeChannel.server != this.originalState.server) {
			console.log('changed')
			this.http.get(`${constants.BASE}:8080/changeServer/${this.activeChannel.id}`)
			.subscribe(resultado => console.log(resultado));
		}
		this.ktDialogService.hide();
	}

	async onSaveSecondary() {
		if(!this.secondaryChannel.channel.whatsapp_name || this.secondaryChannel.channel.whatsapp_name==0) {
			return this.notify.show('Campo Nome Vazio!', 'danger');
		}
		if(!this.secondaryChannel.channel.whatsapp_id || this.secondaryChannel.channel.whatsapp_id==0) {
			return this.notify.show('Campo Número do WhatsApp Vazio!', 'danger');
		}
		this.ktDialogService.show();
		// tslint:disable-next-line:indent
		let notify = ''
		var newChannel = false
		this.secondaryChannel.channel.server = this.serverPick || 10
		await this.api.admin_channel_save(this.secondaryChannel.channel).then( async value => {
			if (value.success) {
				if(!this.secondaryChannel.channel.id) {
					newChannel = true
					this.secondaryChannel.channel.id = value.user.id
					this.secondaryChannel.channel.instance_id = value.user.id
					this.secondaryChannel.channel.token = value.user.token
				}
				if(newChannel) {
					let resp = await this.apiJS.createChannelMongo(this.secondaryChannel.channel)
					// if(!resp.statusCode.includes([200,201])) {
					// 	return this.notify.show("MongoDB: Ocorreu um erro", 'danger');
					// }
				} else {
					// this.socketService.updateDataChannel(this.secondaryChannel.channel)
					this.apiJS.updateChannelMongo(this.secondaryChannel.channel)
				}
				// this.updateAttChannel(this.secondaryChannel.channel)
				notify = value.message
				this.modalService.dismissAll();
				this.load();
			}
			else {
				if(value.fail) {
					this.notify.show(`Campo ${value.fail} Inválido!`, 'danger');
				} else {
					this.notify.show(value.message, 'danger');
				}
				return this.ktDialogService.hide();
			}
		});

		// await this.apiJS.admin_channel_save(this.secondaryChannel.channel).then( async value => {
		// 	if (value.success) {
		// 		if(!this.secondaryChannel.channel.id) {
		// 			newChannel = true
		// 			this.secondaryChannel.channel.id = value.user.id
		// 			this.secondaryChannel.channel.instance_id = value.user.id
		// 			this.secondaryChannel.channel.token = value.user.token
		// 		}
		// 		if(!this.secondaryChannel.status) {
		// 			this.secondaryChannel.status = 'got qr code'
		// 		}
		// 		if(newChannel)
		// 			this.socketService.createChannel(this.secondaryChannel.channel,null)
		// 		else
		// 			this.socketService.updateDataChannel(this.secondaryChannel.channel)
		// 		// this.updateAttChannel(this.secondaryChannel.channel)
		// 		notify = value.message
		// 		this.modalService.dismissAll();
		// 		this.load();
		// 	}
		// 	else {
		// 		if(value.fail) {
		// 			this.notify.show(`Campo ${value.fail} Inválido!`, 'danger');
		// 		} else {
		// 			this.notify.show(value.message, 'danger');
		// 		}
		// 		// return this.ktDialogService.hide();
		// 	}
		// }).catch( async err =>{
		// 	if(err.fail) {
		// 		this.notify.show(`Campo ${err.fail} Inválido!`, 'danger');
		// 	} else if(err.message) {
		// 		this.notify.show(err.message, 'danger');
		// 	} else {
		// 		this.notify.show('Ocorreu um erro, tente novamente!', 'danger');
		// 	}
		// 	// return this.ktDialogService.hide();
		// });
		if(notify) {
			this.secondaryChannel.endpoints.forEach(async endpoint => {
				await this.api.update_endpoint(endpoint.id, endpoint.active,this.secondaryChannel.channel.id)
			});
			this.notify.show(notify, 'success')
		}
		this.ktDialogService.hide()
	}

	async onEdit(channel: any) {
		this.originalState = Object.assign({}, channel)
		this.activeChannel = channel

		if(channel.enable_operators) {
			// const id = channel.main_channel_id || channel.instance_id
			// this.getDepartments(id)
		}

		const channelConfigResp = await this.apiJS.getChannelConfig(channel.instance_id)
		console.log('activeChannel',this.activeChannel,channelConfigResp)
		this.activeChannel.instantAttendance = channelConfigResp.channel.instantAttendance
		this.modalService.open(this.modalChannel, { size: 'sm' })
	}

	async onContainer(channel: any) {
		this.activeChannel = channel
		this.modalService.open(this.modalContainer)
		console.log('activeChannel',this.activeChannel)
	}

	async onDelete(channel: any) {
		if (confirm('Você realmente quer excluir este canal?')) {
			this.ktDialogService.show()
			// tslint:disable-next-line:indent
			if(channel.enable_operators=='1') {
				channel.enable_operators='0'
				if(!channel.main_channel_id) {
					await this.deleteBot(channel)
				}
				channel.deleted = 1
				this.updateAttChannel(channel)
			}
			this.socketService.deleteChannel(channel)
			await this.api.admin_channel_delete(channel.instance_id).then(value => {
				if (value.success) {
					this.notify.show(value.message, 'success')
					this.modalService.dismissAll()
					this.ktDialogService.hide()
					this.load()
				}
			}).catch(error => {
				console.error(error)
				this.notify.show('Ocorreu um erro, tente novamente!', 'danger')
				this.ktDialogService.hide()
			});
			// await this.apiJS.admin_channel_delete(channel.instance_id).then(value => {
			// 	if (value.success) {
			// 		this.notify.show(value.message, 'success');
			// 		this.modalService.dismissAll();
			// 		this.ktDialogService.hide();
			// 		this.load();
			// 	}
			// }).catch(error => {
			// 	console.error(error)
			// 	this.notify.show('Ocorreu um erro, tente novamente!', 'danger');
			// 	this.ktDialogService.hide();
			// });
		}
	}

	setAddressByCEP() {
		cep(this.activeChannel.zipcode).then(value => {
			if (value.city) {
				this.activeChannel.city = value.city;
			}

			if (value.neighborhood) {
				this.activeChannel.neighborhood = value.neighborhood;
			}

			if (value.state) {
				this.activeChannel.state = value.state;
			}

			if (value.street) {
				this.activeChannel.street = value.street;
			}

			this.activeChannel.country = 'BR';

			if (!this.ref['destroyed']) {
				this.ref.detectChanges();
			}
		}).catch(error => {
			console.log(error);
		})
	}

	queue(channel) {
		this.channel = channel
		this.queueCount = {},
		this.modalService.open(this.modalQueue, {size: 'sm'});
		this.loadQueue(channel)
	}

	async getQueue(channelId) {
		// let queue = await this.api.queueTotal(channelId)
		// return queue
	}

	async loadQueue(channel) {
		this.loadingQueue = true;
		this.queueCount.total = await this.api.queueTotal(channel.instance_id)
		this.queueCount.today = await this.api.queueToday(channel.instance_id)
		this.queueCount.previous = await this.api.queuePrevious(channel.instance_id)
		this.loadingQueue = false;
	}

	warnClean(option) {
		this.modalService.open(this.modalClean, {size: 'sm'});
		this.queueClean = option
	}

	redirectTo() {
		this.modalService.open(this.modalRedirectTo, {size: 'sm'});
	}

	async cleanQueue() {
		let result = await this.api.cleanQueue(this.channel.instance_id,this.queueClean)
		this.loadQueue(this.channel)
		if(result.success) {
			return this.notify.show(result.message, 'success');
		} else {
			return this.notify.show(result.message, 'danger');
		}
	}

	openFilter() {
		this.modalService.open(this.modalFilter, {size: 'sm'});
	}

	removeFilter() {
		this.filters = {}
		this.load()
	}

	filterActive() {
		// if(this.filters.option) {
		if(this.filters.keyword || this.filters.server) {
			return true
		}
		return false
	}

	changeOperatorsPanel($event) {
		this.activeChannel.enable_operators = $event.target.checked ? '1' : '0';
		this.activeChannel.instantAttendance = $event.target.checked ? false : this.activeChannel.instantAttendance;
		this.activeChannel.enable_chatbot = $event.target.checked ? '0' : this.activeChannel.enable_chatbot
		this.operatorsChanged = true
	}

	changeInstantAttendance($event) {
		this.activeChannel.instantAttendance = $event.target.checked ? true : false;
		this.activeChannel.enable_operators = $event.target.checked ? '0' : this.activeChannel.enable_operators;
		this.activeChannel.enable_chatbot = $event.target.checked ? '0' : this.activeChannel.enable_chatbot
		this.instantAttendanceChanged = true
	}

	changeChatbot($event) {
		console.log(this.activeChannel.enable_operators,this.activeChannel.instantAttendance)
		this.activeChannel.enable_chatbot = $event.target.checked ? '1' : '0'
		this.activeChannel.enable_operators = $event.target.checked ? '0' : this.activeChannel.enable_operators;
		this.activeChannel.instantAttendance = $event.target.checked ? false : this.activeChannel.instantAttendance;
		this.operatorsChanged = true
	}

	updateAttChannel(channel=null,bot=null) {
		if(!channel) {
			channel= this.activeChannel
		}
		this.socketService.updateAttChannel(channel,bot)
	}

	createBot() {
		return new Promise( async (resolve,reject)=>{
			let email = 'bot.'+this.activeChannel.login
			let senha = 'a1da'
			let bot = {id:'',email,login:email,senha,isOperator:'2',nome:'Bot '+this.activeChannel.whatsapp_name}
			let channelToken = await this.getChannelToken()
			if(channelToken) {
				await this.api.user_save(bot,channelToken).then(value => {
					if (value.success) {
						bot = value.user
						bot.senha = senha
					} else {
						bot = value.user
					}
				});
				// await this.apiJS.user_save(bot,channelToken).then(value => {
				// 	if (value.success) {
				// 		bot = value.user
				// 		bot.senha = senha
				// 	} else {
				// 		bot = value.user
				// 	}
				// });
				// if(bot.id) {
				// } else {
				// 	bot['error'] = true
				// }
			} else {
				bot['error'] = true
				bot['message'] = 'Ocorreu um erro com o token, por favor relogue e tente novamente!'
			}
			resolve(bot)
		})

	}

	async deleteBot(channel=null) {
		return new Promise( async (resolve,reject)=>{
			const del = {success:true,message:''}
			const channelToken = await this.getChannelToken(channel)
			if(channelToken) {
				this.api.bot_remove(channelToken).then(async value => {
					if(!value.success) {
						console.log('erro remove bot',value)
					}
				})
			} else {
				del.success = false
				del.message = 'Ocorreu um erro com o token, por favor relogue e tente novamente!'
			}
			resolve(del)
		})
	}

	getChannelToken(channel=null) {
		if(!channel) {
			channel = this.activeChannel
		}
		return new Promise( async (resolve,reject)=>{
			let token = null
			await this.api.auth(null,null,channel.token).then(async value => {
				if(value.success) {
					token = value.token
				} else {
					console.log('erro token',value)
				}
			})
			// await this.apiJS.auth(null,null,null,channel.token).then(async value => {
			// 	if(value.success) {
			// 		token = value.token
			// 	} else {
			// 		console.log('erro token',value)
			// 	}
			// })
			resolve(token)
		})
	}

	getChannelEndpoints(channel) {
		return new Promise( async (resolve,reject)=>{
			let endpoint = null
			await this.api.auth(null,null,channel.token).then(async value => {
				if(value.success) {
					endpoint = value.endpoints.Endpoints.items
				} else {
					console.log('erro endpoint',value)
				}
			})
			// await this.apiJS.get_channel_endpoints(channel.token).then(async value => {
			// 	if(value.success) {
			// 		endpoint = value.endpoints.Endpoints.items
			// 	} else {
			// 		console.log('erro endpoint',value)
			// 	}
			// })
			resolve(endpoint)
		})
	}

	clipboard(text: string) {
		this.clipboardService.copy(text)
		this.snackBar.open('Token copiado!','Ok', {duration: 3 * 1000});
	}

	async getTotalNotifications(filters) {
		let total = await this.api.count_all_notifications(filters)
		this.sentToday = total.today
		this.sentMonth = total.month
		this.loadingNotifications = false
		this.ref.detectChanges()
	}

	async getTotalContacts(filters) {
		this.contacts = await this.api.all_contacts_notifications(filters)
		this.loadingContacts = false
		this.ref.detectChanges()
	}

	pickServer() {
		return this.modalService.open(this.modalPickServer, {size: 'sm'});
	}

	pickChannelType() {
		this.modalService.open(this.modalPickChannelType, {size: 'sm'});
	}

	mainChannelsFilter(channels) {
		return channels.filter(el=>!el.main_channel_id)
	}

	channelRedirectFilter(channels) {
		return channels.filter(el=>(this.channel.main_channel_id ? el.instance_id==this.channel.main_channel_id || el.main_channel_id==this.channel.main_channel_id || el.main_channel_id==this.channel.instance_id : el.main_channel_id==this.channel.instance_id) && el.instance_id!=this.channel.instance_id)
	}

	searchChannel(item) {
		if(item.whatsapp_id) {
			if(!this.modalPickPriSearch) {
				return true
			}
			if(item.id.includes(this.modalPickPriSearch) || item.whatsapp_name.toLowerCase().includes(this.modalPickPriSearch) || item.whatsapp_id.toLowerCase().includes(this.modalPickPriSearch)) {
				return true
			}
		}
		return false
	}
	searchChannelRedirect(item) {
		if(item.whatsapp_id) {
			if(!this.modalRedirectToSearch) {
				return true
			}
			if(item.id.includes(this.modalRedirectToSearch) || item.whatsapp_name.toLowerCase().includes(this.modalRedirectToSearch) || item.whatsapp_id.toLowerCase().includes(this.modalRedirectToSearch)) {
				return true
			}
		}
		return false
	}

	async pickRedirectChannel(channel) {
		this.modalService.open(this.modalRedirect, {size: 'sm'});
		this.channel.queue_length = await this.getQueue(this.channel.instance_id)
		this.redirectChannel.id = channel.instance_id
		this.redirectChannel.queue_length = await this.getQueue(channel.instance_id)
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}

	doRedirect() {
		if(!this.channel.instance_id || !this.redirectChannel.id) {
			this.modalService.dismissAll();
			this.channel = {}
			this.redirectChannel.id = null
			return this.notify.show('Ocorreu um erro, tente novamente!', 'danger');
		}
		else if(!this.redirectQuantity) {
			return this.notify.show('Quantidade não definida!', 'danger');
		}
		this.apiJS.redirect_from_to(this.channel.instance_id,this.redirectChannel.id,this.redirectQuantity).then( async resp => {
			this.modalService.dismissAll();
			this.load()
			if (resp.success) {
				return this.notify.show(resp.message, 'success');
			}
			console.log('redirect resp',resp)
			return this.notify.show(resp.message, 'danger');
		})
	}

	async setPrimaryChannel(channel) {
		this.ktDialogService.show();
		let endpoints = await this.getChannelEndpoints(channel)
		this.secondaryChannel = {channel: {},endpoints}
		Object.keys(channel).forEach((key) => {
			if (key != 'id' && key != 'instance_id') {
				this.secondaryChannel.channel[key] = channel[key]
			}
		}, {})
		this.secondaryChannel.channel.main_channel_id = channel.id
		this.secondaryChannel.channel.new_password = 'a1da'
		this.secondaryChannel.channel.whatsapp_name = ''
		this.secondaryChannel.channel.whatsapp_id = ''

		this.ktDialogService.hide();
		this.modalService.open(this.modalCreateSecChannel, {size: 'sm'});
	}

	isTarifando() {
		if(this.url=='tarifando') {
			return true
		}
	}

	isStandard() {
		if(this.user.getUser().master=='1') {
			return true
		} else {
			return false
		}
		// if(this.url=='painel' || window.location.href.includes('localhost')) {
		// 	return true
		// }
	}

	setTokenUrl() {
		let url = window.location.href.split('/')[2]
		url = url + '/#/auth/login?t='
		this.tokenUrl = url
	}

	getDepartments(channelId) {
		this.socketService.getDepartments(channelId);
	}

	plansChange(value) {
		if(value=='Adicionar')
			this.modalService.open(this.modalAddPlan, {size: 'sm'});
	}

	async addPlan() {
		// console.log(this.newPlan)
		if(!this.newPlan)
			return this.notify.show('Insira o plano desejado!', 'danger');
		if(this.plans.find(el=>el.value==this.newPlan))
			return this.notify.show('O plano inserido já existe!', 'danger');
		await this.api.add_plan(this.newPlan)
		this.plans = await this.api.get_plans()
		if(!this.plans.find(el=>el.id==0)) {
			this.plans.push({id:'0',value:'Adicionar'})
		}
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
		this.activeChannel.plano_notificacoes = this.newPlan
		return this.notify.show('Plano adicionado com sucesso!', 'success');
	}

	async rechargeBalance() {
		let recharge = this.activeChannel.recharge
		let channelToken = await this.getChannelToken()
		if(channelToken) {
			let res = await this.api.recharge_balance(recharge,channelToken)
			this.activeChannel.recharge = undefined
			if(res.success) {
				this.load()
				return this.notify.show('Recarga efetuada com sucesso!','success')
			}
			else
				return this.notify.show('Ocorreu um erro, atualize a página e tente novamente!','danger')
		}
		this.activeChannel.recharge = undefined
		return this.notify.show('Ocorreu um erro, atualize a página e tente novamente!','danger')
	}

	openExtractFilter() {
		this.modalService.open(this.modalPlanFilter, {size: 'sm'});
	}

	onViewPlanExtract(channel) {
		this.planExtractData = []
		if(channel.main_channel_id) {
			return
		}
		this.activeChannel = channel
		this.loadPlanExtract(channel);
		this.modalService.open(this.modalPlanExtract, {size: 'lg'});
	}

	private async loadPlanExtract(channel) {
		this.ktDialogService.show();
		let channelToken = await this.getChannelToken(channel)
		if(channelToken) {
			this.planExtractData = await this.api.get_plan_extract(0,this.extractFilter,channelToken);
			// console.log('plan',this.planExtractData)
			this.planExtractData.total = this.planExtractData.total[0].quantity
			// console.log(this.planExtractData)
			this.ktDialogService.hide();
			if (!this.ref['destroyed']) {
				this.ref.detectChanges();
			}
		} else {
			return this.notify.show('Ocorreu um erro, atualize a página e tente novamente!','danger')
		}
	}

	sortTable(param) {
		if(!this.sortCount[param])
			this.sortCount[param]=1
		else
			this.sortCount[param]++
		switch (param) {
			case 'id':
				if(this.sortCount[param] % 2) { // odd number
					this.channels = this.channels.sort((a,b) => a.id-b.id) // sort asc
				} else { // even number
					this.channels = this.channels.sort((a,b) => b.id-a.id) // sort desc
				}
			  break;
			case 'channel':
				if(this.sortCount[param] % 2) { // odd number
					this.channels = this.channels.sort((a,b) => (a.whatsapp_name > b.whatsapp_name) ? 1 : ((b.whatsapp_name > a.whatsapp_name) ? -1 : 0)) // sort asc
				} else { // even number
					this.channels = this.channels.sort((a,b) => (a.whatsapp_name < b.whatsapp_name) ? 1 : ((b.whatsapp_name < a.whatsapp_name) ? -1 : 0)) // sort desc
				}
			  break;
			case 'queue':
				if(this.sortCount[param] % 2) { // odd number
					this.channels = this.channels.sort((a,b) => a.queue_length-b.queue_length) // sort asc
				} else { // even number
					this.channels = this.channels.sort((a,b) => b.queue_length-a.queue_length) // sort desc
				}
			  break;
			case 'lastSent':
				if(this.sortCount[param] % 2) { // odd number
					this.channels = this.channels.sort((a,b) => (a.last_sent_message > b.last_sent_message) ? 1 : ((b.last_sent_message > a.last_sent_message) ? -1 : 0)) // sort asc
				} else { // even number
					this.channels = this.channels.sort((a,b) => (a.last_sent_message < b.last_sent_message) ? 1 : ((b.last_sent_message < a.last_sent_message) ? -1 : 0)) // sort desc
				}
			  break;
			case 'lastReceived':
				if(this.sortCount[param] % 2) { // odd number
					this.channels = this.channels.sort((a,b) => (a.last_received_message > b.last_received_message) ? 1 : ((b.last_received_message > a.last_received_message) ? -1 : 0)) // sort asc
				} else { // even number
					this.channels = this.channels.sort((a,b) => (a.last_received_message < b.last_received_message) ? 1 : ((b.last_received_message < a.last_received_message) ? -1 : 0)) // sort desc
				}
			  break;
			case 'status':
				if(this.sortCount[param] % 2) { // odd number
					this.channels = this.channels.sort((a,b) => (a.status_description > b.status_description) ? 1 : ((b.status_description > a.status_description) ? -1 : 0)) // sort asc
				} else { // even number
					this.channels = this.channels.sort((a,b) => (a.status_description < b.status_description) ? 1 : ((b.status_description < a.status_description) ? -1 : 0)) // sort desc
				}
			  break;
			case 'sentThisMonth':
				if(this.sortCount[param] % 2) { // odd number
					this.channels = this.channels.sort((a,b) => a.sent_qty-b.sent_qty) // sort asc
				} else { // even number
					this.channels = this.channels.sort((a,b) => b.sent_qty-a.sent_qty) // sort desc
				}
				break;
			// case 'balance':
			// 	if(this.sortCount[param] % 2) { // odd number
			// 		this.channels = this.channels.sort((a,b) => a.saldo-b.saldo) // sort asc
			// 	} else { // even number
			// 		this.channels = this.channels.sort((a,b) => b.saldo-a.saldo) // sort desc
			// 	}
			//   break;
			// case 'plan':
			// 	if(this.sortCount[param] % 2) { // odd number
			// 		this.channels = this.channels.sort((a,b) => a.plano_notificacoes-b.plano_notificacoes) // sort asc
			// 	} else { // even number
			// 		this.channels = this.channels.sort((a,b) => b.plano_notificacoes-a.plano_notificacoes) // sort desc
			// 	}
			//   break;
		}
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}

	getServers() {
		this.socketService.getServerConfig()
	}

	async createContainer(channelId: string | number, channelNumber: string | number) {
		this.loadingCreateContainer = true
		const resp = await this.api.createContainer(channelId, channelNumber)
		this.loadingCreateContainer = false

		return this.notify.show(resp || 'Erro ao criar container', !resp.includes("Error") ? 'success' : 'danger')
	}

	async recreateContainer(channelId: string | number, channelNumber: string | number) {
		this.loadingRecreateContainer = true
		const resp = await this.api.recreateContainer(channelId, channelNumber)
		this.loadingRecreateContainer = false

		return this.notify.show(resp || 'Erro ao recriar container', !resp.includes("Error") ? 'success' : 'danger')
	}

	async restartContainer(channelId: string | number) {
		this.loadingRestartContainer = true
		const resp = await this.api.restartContainer(channelId)
		this.loadingRestartContainer = false

		return this.notify.show(resp || 'Erro ao reiniciar container', !resp.includes("Error") ? 'success' : 'danger')
	}
}
