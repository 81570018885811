import {Injectable} from '@angular/core';

import * as $ from 'jquery';
import 'bootstrap-notify';

@Injectable({
	providedIn: 'root'
})
export class NotifyService {

	constructor() {
	}

	public show(message, type = 'success') {
		($ as any).notify({
			message
		}, {
			type
		});
	}

}
