// export const BASEURL = 'https://painel.gobot.digital/api/';
// export const BASEURL = 'https://node81302-notifique-dev.jelastic.saveincloud.net/gobot/';
// export const BASEURL = 'http://localhost/api/';
export var BASEURL, JSURL, BASE
let url = window.location.href

if(url && !url.includes('notifiqueai') && !url.includes('gobot')) {
    url = url.replace('https://','').split('.')[1]
} else {
    url = url.replace('https://','').split('.')[0]
}

if(!url) {
    BASE = "https://painel.gobot.digital"
    BASEURL = "http://localhost/api/"
    JSURL = "http://localhost:5999/"
} else if(url == 'dev') {
    BASE = "https://dev.notifiqueai.com.br"
    BASEURL = `https://painel.gobot.digital/api/`;
    JSURL = `${BASE}:5999/`
} else {
    BASE = "https://painel.gobot.digital"
    BASEURL = `${BASE}/api/`;
    JSURL = `${BASE}:5999/`
}

export const API_BASEURL = `${BASEURL}api/`;
export const API_JSURL = `${JSURL}`;