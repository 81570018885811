// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';
import {ContactsComponent} from './views/pages/contacts/contacts.component';
import {ContactComponent} from './views/pages/contact/contact.component';
import {ChatBotComponent} from './views/pages/chat-bot/chat-bot.component';
import {CustomMenuComponent} from './views/pages/custom-menu/custom-menu.component';
import {ChannelComponent} from './views/pages/channel/channel.component';
import {NotificacoesComponent} from './views/pages/notificacoes/notificacoes.component';
import {PainelPrincipalComponent} from './views/pages/painel-principal/painel-principal.component';
import {EditarNotificacaoComponent} from './views/pages/editar-notificacao/editar-notificacao.component';
import {DashboardMainComponent} from './views/pages/dashboard-main/dashboard-main.component';
import {ChannelsComponent} from './views/pages/channels/channels.component';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				redirectTo: 'channels'
			},
			{
				path: 'channels',
				component: ChannelsComponent
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					'type': 'error-v6',
					'code': 403,
					'title': '403... Access forbidden',
					'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'channels', pathMatch: 'full'},
			{path: '**', redirectTo: 'channels', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {useHash: true})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
