// Angular
import {Injectable} from '@angular/core';
// RxJS
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class KtDialogService {
	private ktDialog: any;
	private currentState: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private checkEvent: any = null;

	// Public properties
	constructor(public router: Router) {
		this.router.events.subscribe(value => {
			this.hide();
		});

		this.ktDialog = new KTDialog({'type': 'loader', 'placement': 'top center', 'message': 'Carregando ...'});
	}

	show() {
		this.currentState.next(true);
		this.ktDialog.show();

		this.checkEvent = setTimeout((that) => {
			if (that.checkIsShown()) {
				that.hide();
			}

			that.checkEvent = null;
		}, (10 * 1000), this);
	}

	hide() {
		this.currentState.next(false);
		this.ktDialog.hide();

		if (this.checkEvent) {
			clearTimeout(this.checkEvent);
			this.checkEvent = null;
		}
	}

	checkIsShown() {
		return this.currentState.value;
	}
}
