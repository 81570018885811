import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';
import {
	KtDialogService,
	LayoutConfigService,
	SparklineChartDirective,
	SparklineChartOptions,
	SubheaderService
} from '../../../core/_base/layout';
import {Widget4Data} from '../../partials/content/widgets/widget4/widget4.component';
import {UserService} from '../../../services/user.service';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToolsService} from '../../../services/tools.service';
import { MissionService } from '../../../services/mission.service';
import { Subscription } from 'rxjs';
import * as constants from '../../../constants'

@Component({
	selector: 'kt-dashboard-main',
	templateUrl: './dashboard-main.component.html',
	styleUrls: ['./dashboard-main.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DashboardMainComponent implements OnInit {

	@Input() astronaut = '';
	mission = '<no mission announced>';
	confirmed = false;
	announced = false;
	subscription: Subscription;

	constants = constants

	nBalance = 0;
	nContacts = 0;
	nPlan = 0;

	queue = false;

	constructor(private layoutConfigService: LayoutConfigService,
				public subHeader: SubheaderService,
				public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				private modalService: NgbModal,
				private http: HttpClient,
				private tools: ToolsService,
				private missionService: MissionService,
				) {

		this.subscription = missionService.missionAnnounced$.subscribe(
			mission => {
				this.mission = mission;
				this.announced = true;
				this.confirmed = false;
		});
				
		this.subHeader.setTitle('Painel Principal');
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
		this.load();
	}

	url = ''

	chartOptions1: SparklineChartOptions;
	chartOptions2: SparklineChartOptions;
	chartOptions3: SparklineChartOptions;
	chartOptions4: SparklineChartOptions;
	widget4_1: Widget4Data;
	widget4_2: Widget4Data;
	widget4_3: Widget4Data;
	widget4_4: Widget4Data;

	chartOptionsCommunications: SparklineChartOptions;
	chartOptionsOperators: SparklineChartOptions;

	chartFilters: any = [];
	chartLabels: any = [];
	chartData: any = [];
	checkedFilter: any = 0;

	notifications: any = [];

	notificacoesTemplates = [];
	dtOptions: DataTables.Settings = {};
	notificationsPage: any = 1;

	loading: any = false;

	@ViewChild('modalViewNotification', {static: false}) modalViewNotification: ElementRef;
	@ViewChild('modalDeleteQueue', {static: false}) modalDeleteQueue: ElementRef;
	@ViewChild('modalContacts', {static: false}) modalContacts: ElementRef;
	@ViewChild('notificationsChart', {static: false}) notificationsChart: SparklineChartDirective;
	@ViewChild('modalPlanExtract', {static: false}) modalPlanExtract: ElementRef;
	@ViewChild('modalPlanFilter', {static: false}) modalPlanFilter: ElementRef;
	activeNotification: any = null;

	private planExtractData: any = [];
	private uploads: any = [];
	private attachContacts: any = [];

	width: number
	height: number

	contactSearch: any;
	contacts: any = [];
	sendingReply: any = false;
	reply: any = {
		message: ''
	};
	totalNotifications: any = null;

	search: any = {
		type: 'keyword',
		keyword: '',
		start_date: '',
		end_date: '',
		check: 'simple',
	};

	extractFilter: any = {
		month: '',
		day: '',
		start_date: '',
		end_date:'',
	}

	private autoRefreshEvent = null;

	seeAllBirthdays: any = () => true;
	seeAllCallRecords: any = () => true;

	chartcardRightBtn() {
		document.querySelector('.kt-widget17__items').scrollLeft += 200;
	};
	chartcardLeftBtn() {
		document.querySelector('.kt-widget17__items').scrollLeft -= 200;
	};

	private async load() {
		// this.ktDialogService.show();
		this.loadNotifications();
		this.notificacoesTemplates = await this.api.get_notifications_templates();
		// this.ktDialogService.hide();
		this.setBCP();
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	setBCP(){
		this.nBalance = this.user.getUser().saldo
		this.nContacts = this.user.getUser().quantidade_contatos
		this.nPlan = this.user.getUser().plano_notificacoes
	}

	ngOnDestroy() {
		this.cleanAutoRefresh();
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {

		this.getScreenSize()

		setInterval(()=>{
			if(this.missionService.reload){
				this.reload()
				this.missionService.reloadOff()
			}
		},1)

		this.chartOptions1 = {
			data: [10, 14, 18, 11, 9, 12, 14, 17, 18, 14],
			color: this.layoutConfigService.getConfig('colors.state.success'),
			border: 3
		};
		this.chartOptions2 = {
			data: [11, 12, 18, 13, 11, 12, 15, 13, 19, 15],
			color: this.layoutConfigService.getConfig('colors.state.danger'),
			border: 3
		};
		this.chartOptions3 = {
			data: [12, 12, 18, 11, 15, 12, 13, 16, 11, 18],
			color: this.layoutConfigService.getConfig('colors.state.warning'),
			border: 3
		};
		this.chartOptions4 = {
			data: [11, 9, 13, 18, 13, 15, 14, 13, 18, 15],
			color: this.layoutConfigService.getConfig('colors.state.primary'),
			border: 3
		};

		this.updateNotificationsChart(this.checkedFilter);

		this.chartOptionsOperators = {
			config: {
				type: 'line',
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'],
					datasets: [{
						label: 'Sales Stats',
						backgroundColor: 'rgb(207, 228, 247)', // Chart.helpers.color('#e14c86').alpha(1).rgbString(),  //gradient
						borderColor: '#8ac1f0',

						pointBackgroundColor: 'rgba(0, 0, 0, 0)', // Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: 'rgba(0, 0, 0, 0)', // Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.light'),
						pointHoverBorderColor: 'rgba(255, 255, 255, 0.1)', // Chart.helpers.color('#ffffff').alpha(0.1).rgbString(),

						// fill: 'start',
						data: [
							10, 14, 12, 16, 9, 11, 13, 9, 13, 15
						]
					}]
				},
				options: {
					title: {
						display: false,
					},
					tooltips: {
						mode: 'nearest',
						intersect: false,
						position: 'nearest',
						xPadding: 10,
						yPadding: 10,
						caretPadding: 10
					},
					legend: {
						display: false
					},
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Month'
							}
						}],
						yAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Value'
							},
							ticks: {
								beginAtZero: true
							}
						}]
					},
					elements: {
						line: {
							tension: 0.0000001
						},
						point: {
							radius: 4,
							borderWidth: 12
						}
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 10,
							bottom: 0
						}
					}
				}
			}
		};

		// this.setupAutoRefresh();
	}

	private async loadNotifications(silent = false) {
		this.queue = false;
		this.contacts = [];
		this.loading = true;
		if (!silent) {
			// await this.ktDialogService.show();
		}
		const result = await this.api.get_notifications(this.notificationsPage, this.search);
		this.notifications = result.notifications;
		this.totalNotifications = result.total;
		// console.log(this.notifications)
		if (this.user.getUser().enable_batch_notifications != '1') {
			this.notifications = this.notifications.filter(notification => notification.contato_id && notification.notificacao_id);
		}

		this.notifications.map(notification => {
			notification.hasUnread = notification.mensagens_recebidas.find(e => (!e.lida || e.lida == 0) && !e.processado_webhook);
			return notification;
		});

		this.contacts = this.notifications
		this.loading = false;
		// this.contacts = this.notifications.filter((v, i, a) => a.findIndex(t => (t.destinatario === v.destinatario)) === i); // Unique contacts
		if (!silent) {
			// await this.ktDialogService.hide();
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		}
	}

	getScreenSize(){
		setInterval(()=>{
			this.width  =  document.documentElement.clientWidth || window.innerWidth ||
			document.body.clientWidth;
			this.height =  document.documentElement.clientHeight || window.innerHeight ||
			document.body.clientHeight;
		},1000)
	}

	// uniqByKeepFirst(a, key) {
	// 	let seen = new Set();
	// 	return a.filter(item => {
	// 		let k = key(item);
	// 		return seen.has(k) ? false : seen.add(k);
	// 	});
	// }
	console(str) {
		console.log(str);
	}

	async onNotificationFilter(value) {
		this.queue = false;
		this.checkedFilter = value;
		await this.getChartData(value)
		this.updateNotificationsChart(value);
		if(this.checkedFilter){
			this.search.notificationId = this.checkedFilter
		}else{
			this.search.notificationId = ''
		}
		this.contacts = []
		this.loadNotifications()
		this.notificationsPage = 1
	}

	private updateNotificationsChart(value) {
		this.getChartData(value)
		this.chartOptionsCommunications = {
			config: {
				type: 'line',
				data: {
					labels: this.chartLabels,
					datasets: [{
						label: 'Envio de Notificação',
						backgroundColor: 'rgb(156, 214, 254)', // Chart.helpers.color('#e14c86').alpha(1).rgbString(),  //gradient
						borderColor: '#D2EDFF',

						pointBackgroundColor: 'rgba(0, 0, 0, 0)', // Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: 'rgba(0, 0, 0, 0)', // Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.light'),
						pointHoverBorderColor: 'rgba(255, 255, 255, 0.1)', // Chart.helpers.color('#ffffff').alpha(0.1).rgbString(),

						// fill: 'start',
						data: this.chartData
					}]
				},
				options: {
					title: {
						display: false,
					},
					tooltips: {
						mode: 'nearest',
						intersect: false,
						position: 'nearest',
						xPadding: 10,
						yPadding: 10,
						caretPadding: 10
					},
					legend: {
						display: false
					},
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Month'
							}
						}],
						yAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Value'
							},
							ticks: {
								beginAtZero: true
							}
						}]
					},
					elements: {
						line: {
							tension: 0.0000001
						},
						point: {
							radius: 4,
							borderWidth: 12
						}
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 10,
							bottom: 0
						}
					}
				}
			}
		};
		// this.ref.detectChanges();

		// console.log(this.notificationsChart);
	}

	async onViewNotification(notification) {
		this.ktDialogService.show();
		// this.activeNotification = await this.api.get_notification(notification.id);
		// this.activeNotification.messages = [...this.activeNotification.mensagens_recebidas, ...this.activeNotification.mensagens_enviadas];

		this.activeNotification = await this.api.get_notification_msgs(notification.id);
		this.activeNotification.messages = [notification, ...this.activeNotification.received_msg]

		this.activeNotification.messages.sort((a, b) => {
			const aTimestamp = Date.parse(a.criado_em);
			const bTimestamp = Date.parse(b.criado_em);

			return aTimestamp < bTimestamp ? -1 : 1;
		});

		// console.log('activeNotification',this.activeNotification)
		this.ktDialogService.hide();

		let aux = {id:notification.id}

		this.uploads = [];
		this.attachContacts = [];
		this.reply.message = '';
		notification.hasUnread = false;

		this.modalService.open(this.modalViewNotification, {size: 'sm'});
		this.api.mark_as_read(aux);
		const el = document.querySelector('#scrollMe');
		setTimeout(function() {
			el.scrollTop = el.scrollHeight - el.clientHeight;
		}, 1000);
	}

	onUpload(event, type) {
		console.log(event);

		const fileReader = new FileReader();
		fileReader.onload = (e) => {
			if (e.total >= 16000000) {
				this.notify.show('O tamanho máximo permitido para envios é de 16 MB.');
				return;
			}

			console.log(e);
			const file = {
				data: (e as any).target.result,
				// caption:  this.tools.extractFilename(event.target.value),
				name: event.target.files[0].name,
				// preview: null,
				// icon: null,
				type
			};

			// switch (type) {
			// 	case 'image': {
			// 		file.preview = file.image;
			// 		break;
			// 	}
			// 	case 'video': {
			// 		file.icon = 'la la-file-video-o fa-3x';
			// 		break;
			// 	}
			// 	case 'file': {
			// 		file.icon = 'la la-file-o fa-3x';
			// 		break;
			// 	}
			// }

			this.uploads.push(file);
			this.ref.markForCheck();
		};
		fileReader.readAsDataURL(event.target.files[0]);
	}

	onAttachContacts() {
		this.modalService.open(this.modalContacts, {size: 'sm'});
	}

	onContactCheckboxChange($event: any, contact: any) {
		if ($event.srcElement.checked) {
			this.attachContacts.push(contact);
		} else {
			this.attachContacts = this.attachContacts.filter(item => item.whatsapp !== contact.whatsapp);
		}

		console.log(this.attachContacts);
	}

	async onSendReply() {
		this.sendingReply = true;

		for (const contact of this.attachContacts) {
			await this.api.send_contact(this.activeNotification.contato.whatsapp, contact.whatsapp);
		}

		for (const upload of this.uploads) {
			await this.api.send_file(this.activeNotification.contato.whatsapp, upload.data, upload.name, upload.name);
		}

		await this.api.send_message(this.activeNotification.contato.whatsapp, this.reply.message, this.activeNotification.id).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.reply.message = '';

			this.attachContacts = [];
			this.uploads = [];

			this.sendingReply = false;
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
	}

	onRemoveUploadItem(item: any) {
		this.uploads = this.uploads.filter(e => e !== item);
	}

	onRemoveContactItem(item: any) {
		this.attachContacts = this.attachContacts.filter(e => e !== item);
	}

	// getNotifications($event) {
	// 	this.notificationsPage = $event;
	// 	this.loadNotifications();
	// }

	getNotifications($event) {
		this.notificationsPage = $event;
		if(this.queue){
			this.showQueue(this.notificationsPage)
		}else{
			this.loadNotifications();
		}
	}

	doSearch() {
		this.loadNotifications();
	}

	private cleanAutoRefresh() {
		if (this.autoRefreshEvent) {
			clearTimeout(this.autoRefreshEvent);
			this.autoRefreshEvent = null;
		}
	}

	private setupAutoRefresh() {
		if (this.autoRefreshEvent) {
			this.cleanAutoRefresh();
		}

		let work = null;

		const scheduleWork = () => {
			this.autoRefreshEvent = setTimeout(work, 10 * 1000);
		};

		work = async () => {
			this.cleanAutoRefresh();

			try {
				if (this.notificationsPage) {
					await this.loadNotifications(true);
					await this.updateNotificationsChart(this.checkedFilter);
				}
			} catch (e) {
				console.error(e);
			}

			scheduleWork();
		};

		scheduleWork();
	}

	async reload() {
		// this.ktDialogService.show();
		this.cleanAutoRefresh();

		try {
			if (this.notificationsPage) {
				await this.updateNotificationsChart(this.checkedFilter);
				if(this.queue){
					this.showQueue(1);
				}else{
					await this.loadNotifications();
				}
				await this.setBCP();
			}
		} catch (e) {
			console.error(e);
		}
		// this.ktDialogService.hide();
	}

	trackNotificationItem(index: number, item) {
		return item.id;
	}

	async showQueue(page){
		await this.ktDialogService.show();
		let queue = await this.api.get_queue(page)
		this.queue = true;
		this.contacts = queue.notifications
		this.contacts.queue = true
		this.totalNotifications = queue.total
		await this.ktDialogService.hide();
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	checkAllOptions() {
		if(this.contacts.queue){
			if (this.contacts.every(val => val.checked == true))
				this.contacts.forEach(val => { val.checked = false });
			else
				this.contacts.forEach(val => { val.checked = true });
		}
		
	}

	dismissModal(){
		this.modalService.dismissAll();
	}

	async deleteInQueue(){
		let count = {success: 0, failed: 0};
		for (const element of this.contacts) {
			if(element.checked){
				await this.api.delete_in_queue({'channel_id':this.user.getUser().instance_id,'notification':element}).then(value => {
					count[(value.success ? 'success' : 'failed')]++;
				}).catch(reason => {
					console.log(reason);
				});;
			}
		}
		this.modalService.dismissAll();
		this.notify.show(`Notificações removidas da fila: ${count.success} / Remoções com falha: ${count.failed}.`, 'success');
		this.showQueue(1);
	}

	modalDelete(){
		if(this.contacts.length && this.contacts.queue){
			if(this.contacts.map(val => {if(val.checked){return val}})){
				this.modalService.open(this.modalDeleteQueue, {size: 'sm'});
			}else{
				this.notify.show('Nenhuma notificação selecionada.', 'danger');
			}
		}else{
			this.notify.show('Nenhuma notificação na fila.', 'danger');
		}
	}

	async getChartData(value){
		if(value){
			return await Promise.resolve(this.api.get_chart(value)).then(el=>{
				this.chartLabels = el.map(e => e.date);
				this.chartData = el.map(e=>e.quantity)
			})	
		}
		this.chartLabels = this.user.getDashboardData().dashboard.notifications.filter(e => value === 0 || e.notificacao_id == value).map(e => e.date)
		this.chartData = this.user.getDashboardData().dashboard.notifications.filter(e => value === 0 || e.notificacao_id == value).map(e => e.quantity)
	}

	onViewPlanExtract() {
		this.loadPlanExtract();
		this.modalService.open(this.modalPlanExtract, {size: 'lg'});
	}

	private async loadPlanExtract() {
		this.ktDialogService.show();
		this.planExtractData = await this.api.get_plan_extract(0,this.extractFilter);
		// console.log('plan',this.planExtractData)
		this.planExtractData.total = this.planExtractData.total[0].quantity
		// console.log(this.planExtractData)
		this.ktDialogService.hide();
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	openFilter(){
		this.modalService.open(this.modalPlanFilter, {size: 'sm'});
	}

	removeFilter(){
		this.extractFilter = {}
		this.loadPlanExtract();
	}

	filterActive(){
		if(this.extractFilter.month || this.extractFilter.day || this.extractFilter.startDate || this.extractFilter.finalDate ){
			return true
		}
		return false
	}

	isTarifando(){
		if(this.url=='tarifando'){
			return true
		}
	}

	isStandard(){
		if(this.url=='painel' || this.url=='notifiqueai' || window.location.href.includes('localhost') || this.url=='dev' || this.url=='http://dev'){
			return true
		}
	}
}
