import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorService} from './error.service';
import {UserService} from './user.service';

import * as constants from '../constants';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(public http: HttpClient,
				public error: ErrorService,
				public user: UserService) {
	}

	admin_auth(login, password) {
		return this.http.post(constants.API_BASEURL + 'admin_authenticate', {login, password}).toPromise().then().catch(this.error.handleError);
	}

	auth(login, password, token = null) {
		return this.http.post(constants.API_BASEURL + 'authenticate', {login, password,token}).toPromise().then().catch(this.error.handleError);
	}

	generate_token() {
		return this.http.post(constants.API_BASEURL + 'generate_token', {}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	update_webhook_url(webhook_url) {
		return this.http.post(constants.API_BASEURL + 'update_webhook_url', {webhook_url}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	update_endpoint(id, active,channel_id) {
		return this.http.post(constants.API_BASEURL + 'update_endpoint_sec', {id, active,channel_id}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	update_event(id, active) {
		return this.http.post(constants.API_BASEURL + 'update_event', {id, active}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async get_menus() {
		return this.http.get(constants.API_BASEURL + 'menu', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async menu_add(data = {}) {
		return this.http.post(constants.API_BASEURL + 'menu', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async menu_delete(id) {
		return this.http.delete(`${constants.API_BASEURL}menu/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async menu_save(data, menus) {
		return this.http.post(constants.API_BASEURL + 'menu_save', {data, menus}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async menu_add_media(data) {
		return this.http.post(constants.API_BASEURL + 'menu_add_media', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async menu_media_delete(id: any) {
		return this.http.delete(`${constants.API_BASEURL}menu_media/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_add_api_response(data) {
		return this.http.post(constants.API_BASEURL + 'menu_add_api_response', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_delete_api_response(id: any) {
		return this.http.delete(`${constants.API_BASEURL}menu_api_response/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async channel_save(data) {
		return this.http.post(constants.API_BASEURL + 'channel_save', {data}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	send_message(number, message, reply_message_id = null) {
		return this.http.post(constants.API_BASEURL + 'send_message', {number, message, reply_message_id}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	send_file(number, body, filename, caption) {
		return this.http.post(constants.API_BASEURL + 'send_file', {number, body, filename, caption}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_add_answer_field(data) {
		return this.http.post(constants.API_BASEURL + 'menu_add_answer_field', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_delete_answer_field(id: any) {
		return this.http.delete(`${constants.API_BASEURL}menu_answer_field/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async get_notifications_templates() {
		return this.http.get(constants.API_BASEURL + 'notifications_templates', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	notification_template_save(data) {
		return this.http.post(constants.API_BASEURL + 'notification_template', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	test_notification_template(number, notificationTemplate) {
		return this.http.post(constants.API_BASEURL + 'test_notification_template', {number, notificationTemplate}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async notification_template_delete(id) {
		return this.http.delete(`${constants.API_BASEURL}notification_template/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async get_notifications(page, search) {
		let params = new HttpParams();
		Object.keys(search).forEach(key => {
			params = params.append(key, search[key]);
		});

		return this.http.get(`${constants.API_BASEURL}notifications/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	async get_total_notifications() {
		return this.http.get(`${constants.API_BASEURL}total_notifications`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async send_contact(number, phone) {
		return this.http.post(constants.API_BASEURL + 'send_contact', {number, phone}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_plan_extract(page = 0,filters,channelToken) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${constants.API_BASEURL}plan_extract/${page}`, {
			headers: new HttpHeaders().set('authorization-token', channelToken),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	mark_as_read(notification: any) {
		return this.http.post(`${constants.API_BASEURL}mark_as_read`, {notification_id: notification.id}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async get_channels(page = 0, filters = null) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${constants.API_BASEURL}channels/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	async admin_channel_save(data) {
		return this.http.post(constants.API_BASEURL + 'admin_channel_save', {data}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	admin_channel_delete(id: any) {
		return this.http.delete(`${constants.API_BASEURL}admin_channel/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	queueTotal(id) {
		return this.http.get(`${constants.API_BASEURL}queue_total/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	queueToday(id) {
		return this.http.get(`${constants.API_BASEURL}queue_today/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	queuePrevious(id) {
		return this.http.get(`${constants.API_BASEURL}queue_previous/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	cleanQueue(channel_id,option) {
		return this.http.post(constants.API_BASEURL + 'queue_delete', {channel_id,option}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	user_save(data,channelToken) {
		return this.http.post(constants.API_BASEURL + 'user_save', {data}, {
			headers: new HttpHeaders().set('authorization-token', channelToken)
		}).toPromise().then().catch(this.error.handleError);
	}

	bot_remove(channelToken) {
		return this.http.delete(constants.API_BASEURL + 'bot', {
			headers: new HttpHeaders().set('authorization-token', channelToken)
		}).toPromise().then().catch(this.error.handleError);
	}

	count_all_notifications(filters = null) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${constants.API_BASEURL}count_all_notifications`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	all_contacts_notifications(filters = null) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${constants.API_BASEURL}all_contacts_notifications`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_plans() {
		return this.http.get(`${constants.API_BASEURL}plans`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	add_plan(plan) {
		return this.http.post(`${constants.API_BASEURL}add_plan`, {plan}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	recharge_balance(recharge,channelToken) {
		return this.http.post(`${constants.API_BASEURL}recharge_balance`, {recharge}, {
			headers: new HttpHeaders().set('authorization-token', channelToken)
		}).toPromise().then().catch(this.error.handleError);
	}

	sentThisMonth(id) {
		return this.http.get(`${constants.API_BASEURL}sent_this_month/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	async createContainer(channelId, channelNumber) {
		try {
			return await this.http.get(`https://gotalk.digital:5152/OO3o3IMq594KuHkrarMrWhTQTQ/notifiqueai/create/${channelId}/${channelNumber}`, {
				headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
				responseType: 'text'
			}).toPromise();
		} catch (error) {
			return this.error.handleError(error);
		}
	}

	async recreateContainer(channelId, channelNumber) {
		try {
			return await this.http.get(`https://gotalk.digital:5152/OO3o3IMq594KuHkrarMrWhTQTQ/notifiqueai/recreate/${channelId}/${channelNumber}`, {
				headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
				responseType: 'text'
			}).toPromise();
		} catch (error) {
			return this.error.handleError(error);
		}
	}

	async restartContainer(channelId) {
		try {
			return await this.http.get(`https://gotalk.digital:5152/OO3o3IMq594KuHkrarMrWhTQTQ/notifiqueai/restart/${channelId}`, {
				headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
				responseType: 'text'
			}).toPromise();
		} catch (error) {
			return this.error.handleError(error);
		}
	}
}
