import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private user: any = null;
	private token: any = null;
	private dashboardData: any = null;
	public notificacaoTemplate: any = null;
	private pendingNotificationsCount: any = 0;

	constructor() {
	}

	setUser(user) {
		user.plan_days_left = 0;
		user.plan_days_total = 0;

		const start_date = new Date(user.plan_start);
		const end_date = new Date(user.plan_end);
		const now = new Date();

		user.plan_days_total = (end_date.getTime() - start_date.getTime()) / (1000 * 3600 * 24);
		user.plan_days_left = Math.ceil(((end_date.getTime() - Date.now()) / (1000 * 3600 * 24)));
		user.plan_days_left_percentage = (user.plan_days_left / 30) * 100;

		this.user = user;
	}

	getDashboardData() {
		return this.dashboardData;
	}

	setDashboardData(v) {
		this.dashboardData = v;
	}

	getUser() {
		return this.user;
	}

	setToken(token) {
		this.token = token;
	}

	getToken() {
		return this.token;
	}

	getPendingNotificationsCount() {
		return this.pendingNotificationsCount;
	}

	setPendingNotificationsCount(v) {
		this.pendingNotificationsCount = v;
	}
}
