import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../services/user.service';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {GenericValidatorService} from '../../../services/generic-validator.service';
import * as cep from 'cep-promise';
import {MapsAPILoader} from '@agm/core';

@Component({
	selector: 'kt-channel',
	templateUrl: './channel.component.html',
	styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit {
	userData: any = {};

	imageChangedEvent: any = '';
	croppedImage: any = '';
	showCropper: any = false;

	dataMap: any = {zoom: 15, latitude: null, longitude: null};
	private geoCoder: google.maps.Geocoder;

	@ViewChild('modalEditData', {static: false}) modalEditData: ElementRef;
	days: any = [
		{value: 0, text: 'Domingo'},
		{value: 1, text: 'Segunda-Feira'},
		{value: 2, text: 'Terça-Feira'},
		{value: 3, text: 'Quarta-Feira'},
		{value: 4, text: 'Quinta-Feira'},
		{value: 5, text: 'Sexta-Feira'},
		{value: 6, text: 'Sábado'},
	];

	constructor(private layoutConfigService: LayoutConfigService,
				private modalService: NgbModal,
				public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public genericValidatorService: GenericValidatorService,
				private mapsAPILoader: MapsAPILoader,
				public subHeader: SubheaderService) {
		this.subHeader.setTitle('Canal');

		this.userData = JSON.parse(JSON.stringify(user.getUser()));

		this.dataMap.latitude = this.userData.latitude;
		this.dataMap.latitude = this.userData.longitude;

		this.initMap();
	}

	ngOnInit() {
	}

	onAddDay() {
		this.userData.horarios.push({
			day: 0,
			from: '',
			to: ''
		});
	}

	onRemoveDay(item) {
		this.userData.horarios = this.userData.horarios.filter(e => e !== item);
	}

	async onSave() {
		this.ktDialogService.show();
		await this.api.channel_save(this.userData).then(value => {
			if (value.success) {
				this.notify.show(value.message, 'success');
				this.user.setUser(value.channel);
				this.userData = JSON.parse(JSON.stringify(this.user.getUser()));

				this.modalService.dismissAll();
				this.showCropper = false;
			} else {
				this.notify.show(value.message, 'danger');
			}
		});
		this.ktDialogService.hide();
	}

	onCancel() {
		this.userData = JSON.parse(JSON.stringify(this.user.getUser()));
	}

	onZipcodeUnknown() {
		window.open('http://www.buscacep.correios.com.br/sistemas/buscacep/', '_blank');
	}

	onEditData() {
		this.modalService.open(this.modalEditData);
	}

	onEditTime() {
		// todo
	}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		// show cropper
		this.showCropper = true;
	}

	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
	}

	saveAvatarCrop() {
		this.userData.whatsapp_avatar = this.croppedImage;
		this.userData.whatsapp_avatar_updated = true;

		this.showCropper = false;
	}

	cancelAvatarCrop() {
		this.userData.whatsapp_avatar = JSON.parse(JSON.stringify(this.user.getUser())).whatsapp_avatar;
		this.userData.whatsapp_avatar_updated = null;

		this.showCropper = false;
	}

	async onCep() {
		if (this.userData.zipcode) {
			await this.ktDialogService.show();
			cep(this.userData.zipcode).then(value => {
				if (value.city) {
					this.userData.city = value.city;
				}

				if (value.neighborhood) {
					this.userData.neighborhood = value.neighborhood;
				}

				if (value.state) {
					this.userData.state = value.state;
				}

				if (value.street) {
					this.userData.street = value.street;
				}

				this.userData.country = 'BR';

				this.ref.detectChanges();
			}).catch(error => {
				console.log(error);
			}).finally(() => {
				this.ktDialogService.hide();
			});
		}
	}

	initMap() {
		this.mapsAPILoader.load().then(async () => {
			this.geoCoder = new google.maps.Geocoder;
			this.setMapCurrentLocation();

			// let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
			// 	types: ['address']
			// });
			// autocomplete.addListener('place_changed', () => {
			// 	this.ngZone.run(() => {
			// 		let place: google.maps.places.PlaceResult = autocomplete.getPlace();
			// 		if (place.geometry === undefined || place.geometry === null) {
			// 			return;
			// 		}
			//
			// 		this.dataMap.latitude = place.geometry.location.lat();
			// 		this.dataMap.longitude = place.geometry.location.lng();
			// 		this.dataMap.zoom = 12;
			// 	});
			// });
		});
	}

	setMapCurrentLocation() {
		return new Promise((resolve, reject) => {
			if (this.refreshMapLocation()) {
				return resolve(true);
			}

			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition((position) => {
					this.dataMap.latitude = position.coords.latitude;
					this.dataMap.longitude = position.coords.longitude;
					this.dataMap.zoom = 15;
					resolve(true);
				}, positionError => {
					reject(positionError);
				});
			} else {
				reject(false);
			}
		});
	}

	refreshMapLocation() {
		if (this.userData.street && this.userData.number && this.userData.city && this.userData.zipcode) {
			this.geoCoder.geocode({
				address: `${this.userData.street}, ${this.userData.number}, ${this.userData.city}, ${cep}, ${this.userData.neighborhood}`
			}, (results, status) => {
				if (status === 'OK') {
					this.dataMap.latitude = results[0].geometry.location.lat();
					this.dataMap.longitude = results[0].geometry.location.lng();

					this.userData.latitude = this.dataMap.latitude;
					this.userData.longitude = this.dataMap.longitude;

					this.dataMap.zoom = 15;

					this.ref.detectChanges();

					// window.gmap.setCenter(results[0].geometry.location);
					// var marker = new google.maps.Marker({position: results[0].geometry.location, map: map});
				}
			});

			return true;
		}

		return false;
	}
}
