import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {routerNavigationAction} from '@ngrx/router-store';
import {Router} from '@angular/router';

@Component({
	selector: 'kt-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss']
})

export class ContactsComponent implements OnInit {
	contacts: any = [{
		name: 'Melanie Trump',
		photo: 'assets/media/users/300_21.jpg',
		number: '+ 55 1234567678',
		marriage: 'N/D',
		country: 'Chicago',
		email: 'melaietrump@gmail.com',
		whatsapp: '+ 55 1234567878',
		gender: 'Feminino'
	}, {
		name: 'Melanie Trump',
		photo: 'assets/media/users/300_21.jpg',
		number: '+ 55 1234567678',
		marriage: 'N/D',
		country: 'Chicago',
		email: 'melaietrump@gmail.com',
		whatsapp: '+ 55 1234567878',
		gender: 'Feminino'
	}, {
		name: 'Melanie Trump',
		photo: 'assets/media/users/300_21.jpg',
		number: '+ 55 1234567678',
		marriage: 'N/D',
		country: 'Chicago',
		email: 'melaietrump@gmail.com',
		whatsapp: '+ 55 1234567878',
		gender: 'Feminino'
	}, {
		name: 'Melanie Trump',
		photo: 'assets/media/users/300_21.jpg',
		number: '+ 55 1234567678',
		marriage: 'N/D',
		country: 'Chicago',
		email: 'melaietrump@gmail.com',
		whatsapp: '+ 55 1234567878',
		gender: 'Feminino'
	}, {
		name: 'Melanie Trump',
		photo: 'assets/media/users/300_21.jpg',
		number: '+ 55 1234567678',
		marriage: 'N/D',
		country: 'Chicago',
		email: 'melaietrump@gmail.com',
		whatsapp: '+ 55 1234567878',
		gender: 'Feminino'
	}, {
		name: 'Melanie Trump',
		photo: 'assets/media/users/300_21.jpg',
		number: '+ 55 1234567678',
		marriage: 'N/D',
		country: 'Chicago',
		email: 'melaietrump@gmail.com',
		whatsapp: '+ 55 1234567878',
		gender: 'Feminino'
	},];

	@ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;
	@ViewChild('modalHistoric', {static: false}) modalHistoric: ElementRef;
	@ViewChild('modalSelectOperator', {static: false}) modalSelectOperator: ElementRef;

	private selectedContact: any;
	protocols: any = [{
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, ];
	filterCategory: any = [{
		name: 'Região',
		subcategories: ['Região Norte', 'Região Nordeste', 'Região Centro-Oeste', 'Região Sudeste', 'Região Sul']
	}, {
		name: 'Gênero',
		subcategories: ['Masculino', 'Feminino', 'Outros']
	}, {
		name: 'Whatsapp',
		subcategories: ['Comercial', 'Pessoal']
	}, {
		name: 'Bloqueados',
		subcategories: []
	}, {
		name: 'Pop-ups',
		subcategories: []
	}, {
		name: 'Importados',
		subcategories: []
	}, ];

	constructor(private modalService: NgbModal,
				public router: Router) {
	}

	ngOnInit() {
	}

	onFilterClick() {
		this.modalService.open(this.modalFilter);
	}

	ondotsClick(i) {
		this.selectedContact = this.contacts[i];
		this.modalService.open(this.modalHistoric);
	}

	onOperatorClick() {
		this.modalService.open(this.modalSelectOperator);
	}

	onExpandClick(i) {
		this.protocols[i].expand = !this.protocols[i].expand;
	}

	onContactClick(i) {
		this.router.navigate(['/contact']);
	}
}
