import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';

@Component({
	selector: 'kt-notificacoes',
	templateUrl: './notificacoes.component.html',
	styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit {
	notificacoesTemplates = [];

	@ViewChild('modalDelete', {static: false}) modalDelete: ElementRef;
	@ViewChild('modalSend', {static: false}) modalSend: ElementRef;
	@ViewChild('modalUploadSheet', {static: false}) modalUploadSheet: ElementRef;
	private activeObject: any = null;

	showStatus: any = false;
	showToggle: any = true;
	showEdit: any = true;
	showUploadSheet: any = false;
	showSend: any = false;
	test_number: any = '';
	testingNumber = false;

	readingFile = false;
	arrayBuffer = null;
	sheetData = null;
	uploadingSheet = false;

	constructor(public subHeader: SubheaderService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public user: UserService,
				private modalService: NgbModal) {
		this.subHeader.setTitle('Notificações');

		this.load();

		const isPositus = this.user.getUser().modulos.find(e => e.modulo.nome === 'Positus') && this.user.getUser().modulos.find(e => e.modulo.nome === 'Positus').modulo_id || false;
		this.showEdit = !isPositus;
		this.showToggle = !isPositus;
		this.showSend = isPositus;
		this.showStatus = isPositus;
		this.showUploadSheet = this.user.getUser().enable_batch_notifications == 1;
	}

	ngOnInit() {
	}

	private async load() {
		this.ktDialogService.show();
		this.notificacoesTemplates = await this.api.get_notifications_templates();

		this.ktDialogService.hide();
		this.ref.detectChanges();
	}

	onEdit(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.router.navigateByUrl('/notification-template');
	}

	onUploadSheet(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.modalService.open(this.modalUploadSheet);
	}

	onCreate() {
		this.user.notificacaoTemplate = {
			nome: '',
			icone: '',
			identificador: '',
			conteudo: '',
			modulo_id: this.user.getUser().modulos[0] && this.user.getUser().modulos[0].modulo_id || 0
			// modulo_id: this.user.getUser().modulos.find(e => e.modulo.nome === 'Tray').modulo_id
		};
		this.router.navigateByUrl('/notification-template');
	}

	async onToggleNotificationTemplate(notificacaoTemplate: any) {
		notificacaoTemplate.habilitada = notificacaoTemplate.habilitada == 1 ? 0 : 1;

		this.ktDialogService.show();
		// tslint:disable-next-line:indent
		await this.api.notification_template_save(JSON.parse(JSON.stringify(notificacaoTemplate)));
		this.ktDialogService.hide();
	}

	onDelete(notificacaoTemplate: any) {
		this.activeObject = notificacaoTemplate;
		this.modalService.open(this.modalDelete);
	}

	async doDelete() {
		this.modalService.dismissAll();

		this.ktDialogService.show();
		this.api.notification_template_delete(this.activeObject.id).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
				this.load();
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.activeObject = null;
			this.ref.detectChanges();
		});
		this.ktDialogService.hide();
	}

	doSend() {
		this.testingNumber = true;

		this.api.test_notification_template(this.test_number, this.user.notificacaoTemplate).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.testingNumber = false;
			this.ref.detectChanges();
		});
	}

	onSend(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.modalService.open(this.modalSend);
	}

	incomingfile($event) {
		this.readingFile = true;

		const fileReader = new FileReader();
		fileReader.onload = (e) => {
			this.arrayBuffer = fileReader.result;
			const data = new Uint8Array(this.arrayBuffer);
			const arr = [];
			for (let i = 0; i !== data.length; ++i) {
				arr[i] = String.fromCharCode(data[i]);
			}
			const bstr = arr.join('');
			const workbook = XLSX.read(bstr, {type: 'binary'});
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];

			const jsonData = XLSX.utils.sheet_to_json(worksheet, {raw: true});
			console.log(jsonData);

			// for (const item of jsonData) {
			// 	const contactName = (item as any).Nome || item[Object.keys(item)[0]];
			// 	const contactNumber = (item as any).Telefone || item[Object.keys(item)[1]];
			//
			// 	if (contactName && contactNumber) {
			// 		this.global.model.contacts.push({name: contactName.toString(), number: contactNumber.toString()});
			// 	}
			// }

			this.sheetData = jsonData;

			this.readingFile = false;
			this.ref.markForCheck();
		};
		fileReader.readAsArrayBuffer($event.target.files[0]);
	}

	async doSendSheet() {
		this.uploadingSheet = true;
		const count = {success: 0, failed: 0};

		for (const data of this.sheetData) {
			try {
				await this.api.send_message((data as any).Telefone || data[Object.keys(data)[0]], this.parseSheetContent(this.user.notificacaoTemplate.conteudo, data)).then(value => {
					count[(value.success ? 'success' : 'failed')]++;
				}).catch(reason => {
					console.log(reason);
				});
			} catch (e) {
				console.log(e);
			}
		}

		this.uploadingSheet = false;
		this.sheetData = null;
		this.notify.show(`Processo concluído! Mensagens adicionadas na fila: ${count.success} / Mensagens com falha: ${count.failed}.`, 'success');
	}

	private parseSheetContent(conteudo, data) {
		let res = conteudo;

		for (const key in data) {
			res = res.replaceAll(`[${key}]`, data[key]);
		}

		return res;
	}
}
