// Angular
import {ChangeDetectorRef, Component, ElementRef, Inject, ViewChild, Input} from '@angular/core';
import { Router } from '@angular/router';
import {ToolsService} from '../../../../services/tools.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../../services/user.service';
import {KtDialogService} from '../../../../core/_base/layout';
import {ApiService} from '../../../../services/api.service';
import { MissionService } from '../../../../services/mission.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
	@ViewChild('modalPlanExtract', {static: false}) modalPlanExtract: ElementRef;
	filter: any = {
		start_date: '',
		end_date: ''
	};
	private planExtractData: any = [];

	@Input() astronaut = '';
	mission = '<no mission announced>';
	confirmed = false;
	announced = false;
	subscription: Subscription;
	route = '';


	constructor(public tools: ToolsService,
		private modalService: NgbModal,
		public user: UserService,
		public ktDialogService: KtDialogService,
		public api: ApiService,
		public ref: ChangeDetectorRef,
		private missionService: MissionService,
		private router: Router,
		) {
		this.subscription = missionService.missionAnnounced$.subscribe(
		mission => {
			this.mission = mission;
			this.announced = true;
			this.confirmed = false;
		});
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
	}

	url = ''

	ngOnInit() {
		this.getRoute()
	}

	getRoute(){
		setInterval(()=>{ this.route = this.router.url }, 1000);
	}

	confirm() {
		this.confirmed = true;
		this.missionService.confirmMission(this.astronaut);
	}

	ngOnDestroy() {
		// prevent memory leak when component destroyed
		this.subscription.unsubscribe();
	}

	onViewPlanExtract() {
		this.loadPlanExtract();
		this.modalService.open(this.modalPlanExtract, {size: 'lg'});
	}

	private async loadPlanExtract() {
		this.ktDialogService.show();
		this.planExtractData = await this.api.get_plan_extract(0,'');
		this.ktDialogService.hide();
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}

	refresh(){
		let element = document.querySelector('.refreshBtn i')
		element.classList.toggle("rotate");
		this.confirm()
		setTimeout(()=> { element.classList.toggle("rotate"); }, 3000)
	}

	isTarifando(){
		if(this.url=='tarifando'){
			return true
		}
	}

	isStandard(){
		if(this.url=='painel' || this.url=='notifiqueai' || window.location.href.includes('localhost')){
			return true
		}
	}
}
