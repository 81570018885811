// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService, Login} from '../../../../core/auth';
import {UserService} from '../../../../services/user.service';
import {NotifyService} from '../../../../services/notify.service';
import {ApiService} from '../../../../services/api.service';
import {ApiJsService} from '../../../../services/apiJS.service';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public apiJS: ApiJsService,
		public user: UserService,
		public notify: NotifyService
	) {
		this.unsubscribe = new Subject();
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
		this.checkLogin();
	}

	url = ''

	checkLogin() {
		const login = localStorage.getItem('login');
		const password = localStorage.getItem('password');
		if (login && password) {
			this.data.email = login;
			this.data.password = password;
			this.submit();
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */
	data: any = {email: null, password: null};

	/**
	 * On init
	 */
	ngOnInit(): void {
		// this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	submit() {
		this.api.admin_auth(this.data.email, this.data.password).then(result => {
			if (result.success) {
			this.user.setUser(result.user);
			this.user.setToken(result.token);
			this.user.setDashboardData(result);
			this.user.setPendingNotificationsCount(result.pending_notifications);

			localStorage.setItem('login', this.data.email);
			localStorage.setItem('password', this.data.password);

			this.store.dispatch(new Login({authToken: result.token}));
			this.router.navigateByUrl(this.returnUrl); // Main page
			} else {
				this.authNoticeService.setNotice(result.message, 'danger');
				localStorage.removeItem('password');
			}
		}).catch(reason => {
			this.authNoticeService.setNotice(reason.message, 'danger');
			this.loading = false;
			if (!this.cdr['destroyed']){
				this.cdr.markForCheck();
			}
		});

		// this.apiJS.admin_auth(this.data.email, this.data.password).then(async result => {
		// 	// console.log('auth',result)
		// 	if(result.success){
		// 		this.user.setUser(result.user);
		// 		this.user.setToken(result.token);
		// 		this.store.dispatch(new Login({authToken: result.token}));
		// 		this.router.navigateByUrl(this.returnUrl);
		// 	} else {
		// 		this.authNoticeService.setNotice(result.message, 'danger');
		// 	}
		// }).catch(err => {
		// 	this.authNoticeService.setNotice(err.message, 'danger');
		// 	console.log('auth error',err)
		// })
	}

	isTarifando(){
		if(this.url=='tarifando'){
			return true
		}
	}

	isLight(){
		if(this.url=='desterrosolucoes'){
			return true
		}
	}

	isStandard(){
		if(this.url=='painel' || window.location.href.includes('localhost')){
			return true
		}
	}
}
