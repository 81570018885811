import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
	selector: 'kt-whatsapp',
	templateUrl: './whatsapp.component.html',
	styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
	@Input() message: string = '';

	constructor(public user: UserService) {
	}

	ngOnInit() {
	}

}
