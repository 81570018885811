import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-custom-menu',
	templateUrl: './custom-menu.component.html',
	styleUrls: ['./custom-menu.component.scss']
})
export class CustomMenuComponent implements OnInit {
	// tabs: any = ['Texto', 'Imagens', 'Audio', 'Arquivos', 'Vídeos', 'Localização'];
	tabs: any = ['Texto', 'Imagens', 'Audio', 'Arquivos', 'Vídeos'];
	selectedTab: any = 0;

	menus = [];
	activeMenu: any = '';

	@ViewChild('modalTags', {static: false}) modalTags: ElementRef;

	constructor(public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public subHeader: SubheaderService,
				private modalService: NgbModal) {
		this.subHeader.setTitle('ChatBot');

		this.load();
	}

	ngOnInit() {
	}

	onTabClick(i) {
		this.selectedTab = i;
	}

	private async load() {
		this.ktDialogService.show();
		const menus = (await this.api.get_menus()).sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
		for (const menu of menus) {
			menu.sub_menus = menu.sub_menus.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
		}

		if (this.activeMenu) {
			const menu = menus.find(e => e.id == this.activeMenu.id);
			this.menus[this.menus.indexOf(this.activeMenu)] = menu;
			this.activeMenu = menu;
		} else {
			this.menus = menus;
		}

		this.ktDialogService.hide();

		this.ref.detectChanges();
	}

	onToggleMenuVisibility(item: any) {
		item.visivel = item.visivel == '1' ? '0' : '1';
	}

	async onRemoveMenu(item: any) {
		this.ktDialogService.show();
		await this.api.menu_delete(item.id).then(value => {
			this.activeMenu.sub_menus = this.activeMenu.sub_menus.filter(e => e.id != item.id);
			this.menus = this.menus.filter(e => e.id != item.id);
			this.ref.detectChanges();
		});
		this.ktDialogService.hide();
	}

	async onAddSubMenu() {
		this.ktDialogService.show();
		await this.api.menu_add({item_superior_id: this.activeMenu.id}).then(value => {
			this.activeMenu.sub_menus.push(value.menu);
		});
		this.ktDialogService.hide();

		// await this.load();
	}

	async onAdvance() {
		await this.save();
		this.router.navigateByUrl('/chatbot');
	}

	onFileSelected(type, fieldId) {
		return new Promise(resolve => {
			try {
				const inputNode: any = document.querySelector(fieldId);
				if (typeof (FileReader) !== 'undefined') {
					const reader = new FileReader();

					const fileName = inputNode.files[0].name;

					reader.onload = async (e: any) => {
						if (e.total >= 15000000) {
							this.notify.show('O tamanho máximo permitido para envios é de 15 MB.');
							return;
						}

						await this.saveFile(type, e.target.result, fileName);
						resolve(e.target.result);
					};

					reader.onerror = ev => {
						resolve(null);
					};

					reader.readAsDataURL(inputNode.files[0]);
					this.updateSelectedMenuContentOrder();
				} else {
					resolve(null);
				}
			} catch (e) {
				resolve(null);
			}
		});
	}

	private async saveFile(type, data, file_name) {
		this.ktDialogService.show();
		await this.api.menu_add_media({menu_id: this.activeMenu.id, type, data, file_name}).then(value => {
			if (value.success) {
				this.activeMenu.medias.push(value.media);
				this.updateSelectedMenuContentOrder();
				this.ref.markForCheck();
			}
		});
		this.ktDialogService.hide();
	}

	async onRemoveMedia(item: any) {
		this.ktDialogService.show();
		await this.api.menu_media_delete(item.id).then(value => {
			this.updateSelectedMenuContentOrder();
			this.ref.markForCheck();
		});
		this.ktDialogService.hide();

		await this.load();
	}

	onDrop($event) {
		moveItemInArray(this.activeMenu.ordem_conteudo_array, $event.previousIndex, $event.currentIndex);
	}

	onAddItemEmoji($event: any, item) {
		item.icone = $event.emoji.native;
	}

	onToggleItemEmojiPicker(item: any) {
		item.show_emoji_picker = !item.show_emoji_picker;
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	async save() {
		this.ktDialogService.show();
		await this.api.menu_save(JSON.parse(JSON.stringify(this.user.getUser())), this.menus).then(value => {
			if (value.success) {
				this.notify.show(value.message, 'success');
				this.user.setUser(value.user);
			}
		});
		this.ktDialogService.hide();
	}

	onSubMenuDrop($event, item) {
		moveItemInArray(item.sub_menus, $event.previousIndex, $event.currentIndex);
	}

	onSelectMenu() {
		if (!this.activeMenu.ordem_conteudo_array) {
			try {
				this.activeMenu.ordem_conteudo_array = JSON.parse(this.activeMenu.ordem_conteudo);
			} catch (e) {
			}

			if (!this.activeMenu.ordem_conteudo_array) {
				this.activeMenu.ordem_conteudo_array = [
					{tipo: 'Texto'},
					{tipo: 'Imagem'},
					{tipo: 'Aúdio'},
					{tipo: 'Vídeo'},
					{tipo: 'Localização'},
				];
			}
		}

		this.updateSelectedMenuContentOrder();
	}

	async onAddApiResponse() {
		this.ktDialogService.show();
		await this.api.menu_add_api_response({menu_id: this.activeMenu.id}).then(value => {
			this.activeMenu.api_responses.push(value.item);
			this.ref.detectChanges();
		});
		this.ktDialogService.hide();
	}

	async onRemoveApiResponse(item: any) {
		this.ktDialogService.show();
		await this.api.menu_delete_api_response(item.id).then(value => {
			this.activeMenu.api_responses = this.activeMenu.api_responses.filter(e => e.id != item.id);
			this.ref.detectChanges();
		});
		this.ktDialogService.hide();
	}

	private updateSelectedMenuContentOrder() {
		console.debug('[updateSelectedMenuContentOrder]', {activeMenu: this.activeMenu});

		this.activeMenu.ordem_conteudo_array = [
			{tipo: 'Texto'},
			{tipo: 'Imagem'},
			{tipo: 'Aúdio'},
			{tipo: 'Vídeo'},
			{tipo: 'Localização'},
			{tipo: 'Arquivo'},
		].filter(e => {
			switch (e.tipo) {
				case 'Texto': {
					return !!this.activeMenu.conteudo;
				}
				case 'Imagem': {
					return this.activeMenu.medias.filter(media => media.tipo === 'Imagem').length > 0;
				}
				case 'Aúdio': {
					return this.activeMenu.medias.filter(media => media.tipo === 'Aúdio').length > 0;
				}
				case 'Vídeo': {
					return this.activeMenu.medias.filter(media => media.tipo === 'Vídeo').length > 0;
				}
				case 'Arquivo': {
					return this.activeMenu.medias.filter(media => media.tipo === 'File').length > 0;
				}
				case 'Localização': {
					return false; // todo
				}
			}
		});
	}

	onTags() {
		this.modalService.open(this.modalTags);
	}

	async onAddAnswerField() {
		this.ktDialogService.show();
		await this.api.menu_add_answer_field({menu_id: this.activeMenu.id}).then(value => {
			this.activeMenu.answer_fields.push(value.item);
			this.ref.detectChanges();
		});
		this.ktDialogService.hide();
	}

	async onRemoveAnswerField(item: any) {
		this.ktDialogService.show();
		await this.api.menu_delete_answer_field(item.id).then(value => {
			this.activeMenu.answer_fields = this.activeMenu.answer_fields.filter(e => e.id != item.id);
			this.ref.detectChanges();
		});
		this.ktDialogService.hide();
	}
}
