import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../services/user.service';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {ToolsService} from '../../../services/tools.service';

@Component({
	selector: 'kt-chat-bot',
	templateUrl: './chat-bot.component.html',
	styleUrls: ['./chat-bot.component.scss'],
	host: {
		'(document:mousedown)': 'onClick($event)'
	}
})
export class ChatBotComponent implements OnInit {
	menus = [];
	userData: any = {};
	showEmojiPicker = false;

	@ViewChild('modalTags', {static: false}) modalTags: ElementRef;
	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;

	constructor(private layoutConfigService: LayoutConfigService,
				private modalService: NgbModal,
				public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public toolsService: ToolsService,
				public subHeader: SubheaderService) {
		this.subHeader.setTitle('ChatBot');
		this.userData = JSON.parse(JSON.stringify(user.getUser()));

		this.load();
	}

	ngOnInit() {
	}

	private async load() {
		this.ktDialogService.show();
		this.menus = (await this.api.get_menus()).sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
		this.ktDialogService.hide();

		this.ref.detectChanges();
	}

	onToggleMenuVisibility(item: any) {
		item.visivel = item.visivel == '1' ? '0' : '1';
	}

	async onAddNewMenu() {
		this.ktDialogService.show();
		await this.api.menu_add().then(value => {
			if (value.success) {
				this.menus.push(value.menu);
				this.ref.detectChanges();
			}
		});
		this.ktDialogService.hide();

		// await this.load();
	}

	async onAdvance() {
		await this.save();
		this.router.navigateByUrl('/custom-menu');
	}

	async onRemoveMenu(item: any) {
		this.ktDialogService.show();
		await this.api.menu_delete(item.id).then(value => {
			this.menus = this.menus.filter(e => e.id != item.id);
			this.ref.detectChanges();
		});
		this.ktDialogService.hide();

		// await this.load();
	}

	onTags() {
		this.modalService.open(this.modalTags);
	}

	onFileSelected() {
		return new Promise(resolve => {
			try {
				const inputNode: any = document.querySelector('#file');
				if (typeof (FileReader) !== 'undefined') {
					const reader = new FileReader();

					reader.onload = (e: any) => {
						this.userData.new_greeting_image = true;
						this.userData.greeting_image = e.target.result;
						this.ref.markForCheck();
						resolve(e.target.result);
					};

					reader.onerror = ev => {
						resolve(null);
					};

					reader.readAsDataURL(inputNode.files[0]);
				} else {
					resolve(null);
				}
			} catch (e) {
				resolve(null);
			}
		});
	}

	onAddEmoji($event: any, inputId?) {
		if (!this.userData.greeting_text) {
			this.userData.greeting_text = '';
		}

		if (inputId) {
			this.toolsService.insertAtCaret(inputId, $event.emoji.native);
			this.userData.greeting_text = (document.getElementById(inputId) as any).value;
		} else {
			this.userData.greeting_text += $event.emoji.native;
		}
	}

	toggleEmojiPicker(event) {
		if (!this.showEmojiPicker) {
			const that = this;
			setTimeout(() => {
				that.showEmojiPicker = !that.showEmojiPicker;
				that.ref.markForCheck();
			}, 50);

		}

		event.stopPropagation();
		event.preventDefault();
		return false;
	}

	async save() {
		this.ktDialogService.show();
		await this.api.menu_save(this.userData, this.menus).then(value => {
			if (value.success) {
				this.user.setUser(value.user);
				this.userData = JSON.parse(JSON.stringify(this.user.getUser()));
			}
		});
		this.ktDialogService.hide();
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	onClick($event: any) {
		const that = this;
		if (that.showEmojiPicker) {
			setTimeout(() => {
				if (that.showEmojiPicker && !that.emojiMart.nativeElement.contains($event.target)) {
					that.showEmojiPicker = false;
				}
			}, 50);
		}
	}

	onAddItemEmoji($event: any, item) {
		item.icone = $event.emoji.native;
	}

	onToggleItemEmojiPicker(item: any) {
		item.show_emoji_picker = !item.show_emoji_picker;
	}

	onDrop($event) {
		moveItemInArray(this.menus, $event.previousIndex, $event.currentIndex);
	}
}
