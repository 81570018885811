// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService, Login} from '../../../../core/auth';
import {UserService} from '../../../../services/user.service';
import {NotifyService} from '../../../../services/notify.service';
import {ApiService} from '../../../../services/api.service';
import {ApiJsService} from '../../../../services/apiJS.service';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	authToken;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public apiJS: ApiJsService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
		private snackBar: MatSnackBar,
	) {
		this.unsubscribe = new Subject();
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')) {
			url = url.replace('https://','').split('.')[1]
		} else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
	}

	@ViewChild('modalSelectChannel', {static: false}) modalSelectChannel: ElementRef;

	url = ''

	checkLogin() {
		const login = localStorage.getItem('login');
		const password = localStorage.getItem('password');
		if (login && password) {
			this.data.email = login;
			this.data.password = password;
			this.submit();
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */
	data: any = {email: null, password: null};
	userChannels: any = '';
	selectedChannel: any = '';

	ngOnInit(): void {
		this.route.queryParams.subscribe(async params => {
			this.returnUrl = params['returnUrl'] || '/';
			if(params['t']) {
				this.authToken = params['t'];
				this.apiAuth()
			}
			else if(params['logout']) {
				await localStorage.clear()
				if(params['logout']=='outside_working_hours') this.openSnackBar('Fora do horário de serviço do Operador!','Ok', {duration: 5 * 1000})
				else if(params['logout']=='disconnected_operator') this.openSnackBar('Seu usuário logou em outro local!','Ok')
			} else{
				this.checkLogin();
			}
		});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	submit() {
		this.loading = true;
		this.router.navigate([], {
			queryParams: {
			  'logout': null
			},
			queryParamsHandling: 'merge'
		})
		this.apiAuth()
	}
	
	apiAuth() {
		this.api
			.auth(this.data.email, this.data.password, this.selectedChannel,this.authToken).then(async result => {
			if (result.success) {
				// localStorage.setItem('login', this.data.email);
				// localStorage.setItem('password', this.data.password);
				if(!window.location.href.includes('localhost') && (result.user.isOperator=='1' && (!result.user.admin || result.user.admin<1))) {
					return window.location.href = `/atendimento?t=${result.user.token}`
				}
				await this.user.setUser(result.user);
				await this.user.setToken(result.token);
				await this.user.setDashboardData(result);
				await this.user.setPendingNotificationsCount(result.pending_notifications);

				this.store.dispatch(new Login({authToken: result.token}));
				this.router.navigateByUrl(result.user.permissoes ? '/groups' : this.returnUrl); // Main page
				this.router.navigateByUrl('/dashboard-main')
			
			} else if(result.channels) {
				this.userChannels = result.channels
				this.modalService.open(this.modalSelectChannel, {size: 'sm'});
		 	} else {
				this.authNoticeService.setNotice(result.message || 'Não foi possível completar sua requisição, por favor tente novamente.', 'danger');
				// localStorage.removeItem('password');
			}
		}).catch(reason => {
			this.authNoticeService.setNotice(reason.message, 'danger');
			this.loading = false;
			this.cdr.markForCheck();
		}).finally(() => this.loading = false);

		// this.apiJS.auth(this.data.email, this.data.password, this.selectedChannel,this.authToken).then(async result => {
		// 	// console.log('auth',result)
		// 	await this.user.setUser(result.user);
		// 	await this.user.setToken(result.token);
		// 	this.store.dispatch(new Login({authToken: result.token}));
		// 	this.router.navigateByUrl('/dashboard-main')
		// }).catch(err => {
		// 	this.authNoticeService.setNotice(err.message, 'danger');
		// 	this.loading = false;
		// 	console.log('auth error',err)
		// })
	}

	selectChannel() {
		this.loading = true;
		this.apiAuth()
		this.modalService.dismissAll();
	}

	dismissModal() {
		this.modalService.dismissAll();
	}

	openSnackBar(message: string, action: string, duration=null) {
		this.snackBar.open(message, action,duration);
	}

	isTarifando() {
		if(this.url=='tarifando') {
			return true
		}
	}

	isLight() {
		if(this.url=='desterrosolucoes') {
			return true
		}
	}

	isStandard() {
		if(this.url=='painel' || this.url=='notifiqueai' || window.location.href.includes('localhost') || this.url=='dev' || this.url=='http://dev') {
			return true
		}
	}
}