import {Component, Input, OnInit} from '@angular/core';
import {ToolsService} from '../../../services/tools.service';

@Component({
	selector: 'kt-chat-messages-container',
	templateUrl: './chat-messages-container.component.html',
	styleUrls: ['./chat-messages-container.component.scss']
})
export class ChatMessagesContainerComponent implements OnInit {
	@Input() protocols: any;

	constructor(public tools: ToolsService) {
	}

	ngOnInit() {
	}

}
