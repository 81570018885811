import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorService} from './error.service';
import {UserService} from './user.service';

import * as constants from '../constants';

@Injectable({
	providedIn: 'root'
})
export class ApiJsService {

	baseUrl: string = constants.BASE;
	url = ''

	constructor(public http: HttpClient,
				public error: ErrorService,
				public user: UserService) {
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
	}

	admin_auth(login, password) {
		return this.http.post(constants.API_JSURL + 'adminAuthenticate', {login, password}).toPromise().then().catch(this.error.handleError);
	}

	auth(login, password, channel = null, token = null) {
		return this.http.post(constants.API_JSURL + 'authenticate', {login, password,channel,token}).toPromise().then().catch(this.error.handleError);
	}

	admin_channel_save(channel) {
		return this.http.post(constants.API_JSURL + 'channelSave', channel, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	redirect_from_to(from,to,quantity){
		return this.http.post(`${this.baseUrl}:5970/redirect_from_to`, {from,to,quantity}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_plans() {
		return this.http.get(`${constants.API_JSURL}plans`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_channels(page = 0, filters = null) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${constants.API_JSURL}channels/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	user_save(data,channelToken) {
		return this.http.post(`${constants.API_JSURL}userSave`, {data}, {
			headers: new HttpHeaders().set('authorization-token', channelToken)
		}).toPromise().then().catch(this.error.handleError);
	}

	get_channel_endpoints(channelToken) {
		return this.http.get(`${constants.API_JSURL}userSave`,  {
			headers: new HttpHeaders().set('authorization-token', channelToken)
		}).toPromise().then().catch(this.error.handleError);
	}

	admin_channel_delete(id: any) {
		return this.http.delete(`${constants.API_JSURL}adminChannel/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}
	
	createChannelMongo(channel,bot=null){
		return this.http.post(`${this.baseUrl}:5990/channel`, {channel,bot}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	updateChannelMongo(channel){
		return this.http.put(`${this.baseUrl}:5990/channel`, channel, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	updateChannelConfigMongo(channel){
		return this.http.put(`${this.baseUrl}:5990/channel/config`, channel, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getChannelConfig(channelId) {
		return this.http.get(`${this.baseUrl}:5990/channel/config/${channelId}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}
}
