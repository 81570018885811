declare var require: any

const { io } = require("socket.io-client");
import {HttpClientModule,HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorService} from './error.service';
import {UserService} from './user.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    socket;
    url = '';

    constructor(public http: HttpClient,
        public httpClient: HttpClientModule, 
        public error: ErrorService,
        public user: UserService,
        private router: Router,
        ) {
        if(this.router.url!='/auth/login'){
            if(!this.socket){
                this.socket = this.connect(this.socket,user)
            }

            this.socket.on('disconnect',()=>{
                console.log('disconnect')
                setTimeout(()=>{
                    this.socket = this.connect(this.socket,user)
                },3000)
            })
        }
        let url = window.location.href
        if(!url.includes('notifiqueai') && !url.includes('gobot')){
            url = url.replace('https://','').split('.')[1]
        }else{
            url = url.replace('https://','').split('.')[0]
        }
        this.url = url
    }

    connect(socket,user){
        let addr = 'https://gobot.digital:5969'
        // let addr = 'https://dev.notifiqueai.com.br:5969'
        if(this.url=='dev' || this.url=='http://dev'){
            addr = 'https://dev.notifiqueai.com.br:5969'
        }

        socket = io.connect(addr, {query: {token:user.getToken()} } );
        console.log('connect',socket)
        return socket
    }

    updateAttChannel(channel,bot){
        this.socket.emit('update_channel',{channel,bot})
    }

    getEndpoints() {
        this.socket.emit('getEndpoints')
    }

    onSetEndpoints() {
        return new Observable(observer => {
            this.socket.on('setEndpoints', (data) => {
                observer.next(data);
            });
        });
    }

    getOperators(id) {
        this.socket.emit('get_operators',id)
    }

    onSetOperators() {
        return new Observable(observer => {
            this.socket.on('get_operators', (data) => {
                observer.next(data);
            });
        });
    }
    onNewOperator() {
        return new Observable(observer => {
            this.socket.on('new_operator', (data) => {
                observer.next(data);
            });
        });
    }

    saveOperator(operator) {
        this.socket.emit('new_operator',operator)
    }

    deleteOperator(id){
        this.socket.emit('delete_operator',id)
    }

    getDepartments(channelId) {
        this.socket.emit('get_departments',{channelId})
    }

    onSetDepartments() {
        return new Observable(observer => {
            this.socket.on('get_departments', (data) => {
                observer.next(data);
            });
        });
    }

    onNewDepartment() {
        return new Observable(observer => {
            this.socket.on('new_department', (data) => {
                observer.next(data);
            });
        });
    }

    saveDepartment(department){
        this.socket.emit('new_department',department)
    }

    deleteDepartment(id){
        this.socket.emit('delete_department',id)
    }

    getMenus(channelId){
        this.socket.emit('get_menus',channelId)
    }

    onSetMenus() {
        return new Observable(observer => {
            this.socket.on('get_menus', (data) => {
                observer.next(data);
            });
        });
    }

    onNewMenu() {
        return new Observable(observer => {
            this.socket.on('create_menu', (data) => {
                observer.next(data);
            });
        });
    }

    saveMenu(menu){
        this.socket.emit('create_menu',menu)
    }

    updateMenu(menu){
        this.socket.emit('update_menu',menu)
    }

    deleteMenu(id){
        this.socket.emit('delete_menu',id)
    }

    getChannelConfig(channelId){
        this.socket.emit('get_channel_config',channelId)
    }

    updateChannelConfig(config){
        this.socket.emit('update_channel_config',config)
    }

    onSetChannelConfig() {
        return new Observable(observer => {
            this.socket.on('get_channel_config', (data) => {
                observer.next(data);
            });
        });
    }

    getServerConfig(){
        this.socket.emit('get_server_config')
    }

    onSetServerConfig() {
        return new Observable(observer => {
            this.socket.on('get_server_config', (data) => {
                observer.next(data);
            });
        });
    }

    updateDataChannel(channel){
        // console.log('update_data',channel)
        this.socket.emit('update_data_channel',channel)
    }

    createChannel(channel,bot){
        this.socket.emit('create_channel',{channel,bot})
    }

    deleteChannel(channel){
        this.socket.emit('delete_channel',{channel})
    }
}