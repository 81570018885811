import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';

@Component({
	selector: 'kt-editar-notificacao',
	templateUrl: './editar-notificacao.component.html',
	styleUrls: ['./editar-notificacao.component.scss'],
	host: {
		'(document:mousedown)': 'onClick($event)'
	}
})
export class EditarNotificacaoComponent implements OnInit {
	private showEmojiPicker = false;
	test: any = {number: null};
	testingNumber: any = false;

	@ViewChild('modalTags', {static: false}) modalTags: ElementRef;
	@ViewChild('modalIdentifiers', {static: false}) modalIdentifiers: ElementRef;
	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;

	constructor(public user: UserService,
				public ref: ChangeDetectorRef,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public router: Router,
				public subHeader: SubheaderService,
				private modalService: NgbModal,
				private _location: Location) {
	}

	ngOnInit() {
	}

	/**
	 * Volta a página de notificações.
	 *
	 * @author Daniel Silva Marcelino
	 */
	public back(): void {
		this.router.navigateByUrl('/notifications');
	}

	onTest() {
		this.testingNumber = true;

		this.api.test_notification_template(this.test.number, this.user.notificacaoTemplate).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.testingNumber = false;
			this.ref.detectChanges();
		});
	}

	async onSave() {
		this.ktDialogService.show();
		// tslint:disable-next-line:indent
		await this.api.notification_template_save(JSON.parse(JSON.stringify(this.user.notificacaoTemplate))).then(value => {
			if (value.success) {
				this.notify.show(value.message, 'success');
				this.back();
			}
		});
		this.ktDialogService.hide();
	}

	onCancel() {
		this._location.back();
	}

	toggleEmojiPicker(event = null) {
		if (!this.showEmojiPicker) {
			const that = this;
			setTimeout(() => {
				that.showEmojiPicker = !that.showEmojiPicker;
				that.ref.markForCheck();
			}, 50);

		}

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		return false;
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	onEmoji($event: any) {
		this.user.notificacaoTemplate.conteudo += $event.emoji.native;
		this.toggleEmojiPicker();
	}

	onTags() {
		this.modalService.open(this.modalTags);
	}

	onClick($event: any) {
		const that = this;
		if (that.showEmojiPicker) {
			setTimeout(() => {
				if (that.showEmojiPicker && !that.emojiMart.nativeElement.contains($event.target)) {
					that.showEmojiPicker = false;
				}
			}, 50);
		}
	}

	onIdentifier() {
		this.modalService.open(this.modalIdentifiers);
	}
}
