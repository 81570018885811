// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Partials
import {PartialsModule} from '../partials/partials.module';
// Pages
import {CoreModule} from '../../core/core.module';
import {MailModule} from './apps/mail/mail.module';
import {ECommerceModule} from './apps/e-commerce/e-commerce.module';
import {UserManagementModule} from './user-management/user-management.module';
import {MyPageComponent} from './my-page/my-page.component';
import {ContactsComponent} from './contacts/contacts.component';
import {ContactComponent} from './contact/contact.component';
import {ChatMessageComponent} from '../../gobot/components/chat-message/chat-message.component';
import {
	MatAutocompleteModule,
	MatButtonModule, MatCardModule,
	MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatIconModule,
	MatInputModule,
	MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
	MatSelectModule, MatSnackBarModule, MatSortModule,
	MatTableModule, MatTabsModule, MatTooltipModule
} from '@angular/material';
import {TooltipModule} from 'ng2-tooltip-directive';
import {ChatBotComponent} from './chat-bot/chat-bot.component';
import {RouterModule} from '@angular/router';
import {CustomMenuComponent} from './custom-menu/custom-menu.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ChannelComponent} from './channel/channel.component';
import {NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import { NotificacoesComponent } from './notificacoes/notificacoes.component';
import {PainelPrincipalComponent} from './painel-principal/painel-principal.component';
import {PaginationModule} from './pagination/pagination.module';
import {EditarNotificacaoComponent} from './editar-notificacao/editar-notificacao.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import {DataTablesModule} from 'angular-datatables';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { ChannelsComponent } from './channels/channels.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
	declarations: [MyPageComponent, ContactsComponent, ContactComponent, ChatBotComponent, ChannelComponent, CustomMenuComponent, NotificacoesComponent, PainelPrincipalComponent, EditarNotificacaoComponent, DashboardMainComponent, ChannelsComponent, ChatMessageComponent],
	exports: [],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        MailModule,
        ECommerceModule,
        UserManagementModule,
        MatCheckboxModule,
        TooltipModule,
        RouterModule,
        PickerModule,
        DragDropModule,
        NgbDropdownModule,
        SharedModule,

        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatFormFieldModule,
        PaginationModule,
        DataTablesModule,
        NgbTooltipModule,
        PerfectScrollbarModule,
        NgxMaskModule.forChild(),
        MatChipsModule,
    ],
	providers: []
})
export class PagesModule {
}
