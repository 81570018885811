import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
	KtDialogService,
	LayoutConfigService,
	SparklineChartDirective,
	SparklineChartOptions,
	SubheaderService
} from '../../../core/_base/layout';
import {Widget4Data} from '../../partials/content/widgets/widget4/widget4.component';
import {UserService} from '../../../services/user.service';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToolsService} from '../../../services/tools.service';

@Component({
	selector: 'kt-dashboard-main',
	templateUrl: './dashboard-main.component.html',
	styleUrls: ['./dashboard-main.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DashboardMainComponent implements OnInit {
	chartOptions1: SparklineChartOptions;
	chartOptions2: SparklineChartOptions;
	chartOptions3: SparklineChartOptions;
	chartOptions4: SparklineChartOptions;
	widget4_1: Widget4Data;
	widget4_2: Widget4Data;
	widget4_3: Widget4Data;
	widget4_4: Widget4Data;

	chartOptionsCommunications: SparklineChartOptions;
	chartOptionsOperators: SparklineChartOptions;

	seeAllBirthdays: any = () => true;
	seeAllCallRecords: any = () => true;

	chartFilters: any = [];
	checkedFilter: any = 0;

	notifications: any = [];

	notificacoesTemplates = [];
	dtOptions: DataTables.Settings = {};
	notificationsPage: any = 0;

	@ViewChild('modalViewNotification', {static: false}) modalViewNotification: ElementRef;
	@ViewChild('modalContacts', {static: false}) modalContacts: ElementRef;
	@ViewChild('notificationsChart', {static: false}) notificationsChart: SparklineChartDirective;
	activeNotification: any = null;

	private uploads: any = [];
	private attachContacts: any = [];

	contactSearch: any;
	contacts: any = [];
	sendingReply: any = false;
	reply: any = {
		message: ''
	};
	totalNotifications: any = null;

	search: any = {
		type: 'keyword',
		keyword: '',
		start_date: '',
		end_date: ''
	};

	constructor(private layoutConfigService: LayoutConfigService,
				public subHeader: SubheaderService,
				public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				private modalService: NgbModal,
				private http: HttpClient,
				private tools: ToolsService) {
		this.subHeader.setTitle('Painel Principal');

		this.load();
		// this.loadNotifications();
	}

	private async load() {
		this.ktDialogService.show();
		this.notificacoesTemplates = await this.api.get_notifications_templates();
		this.totalNotifications = await this.api.get_total_notifications();
		this.ktDialogService.hide();
		this.ref.detectChanges();
	}

	ngOnInit(): void {
		this.chartOptions1 = {
			data: [10, 14, 18, 11, 9, 12, 14, 17, 18, 14],
			color: this.layoutConfigService.getConfig('colors.state.success'),
			border: 3
		};
		this.chartOptions2 = {
			data: [11, 12, 18, 13, 11, 12, 15, 13, 19, 15],
			color: this.layoutConfigService.getConfig('colors.state.danger'),
			border: 3
		};
		this.chartOptions3 = {
			data: [12, 12, 18, 11, 15, 12, 13, 16, 11, 18],
			color: this.layoutConfigService.getConfig('colors.state.warning'),
			border: 3
		};
		this.chartOptions4 = {
			data: [11, 9, 13, 18, 13, 15, 14, 13, 18, 15],
			color: this.layoutConfigService.getConfig('colors.state.primary'),
			border: 3
		};

		this.updateNotificationsChart(this.checkedFilter);

		this.chartOptionsOperators = {
			config: {
				type: 'line',
				data: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'],
					datasets: [{
						label: 'Sales Stats',
						backgroundColor: 'rgb(207, 228, 247)', //Chart.helpers.color('#e14c86').alpha(1).rgbString(),  //gradient
						borderColor: '#8ac1f0',

						pointBackgroundColor: 'rgba(0, 0, 0, 0)', //Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: 'rgba(0, 0, 0, 0)', // Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.light'),
						pointHoverBorderColor: 'rgba(255, 255, 255, 0.1)', // Chart.helpers.color('#ffffff').alpha(0.1).rgbString(),

						//fill: 'start',
						data: [
							10, 14, 12, 16, 9, 11, 13, 9, 13, 15
						]
					}]
				},
				options: {
					title: {
						display: false,
					},
					tooltips: {
						mode: 'nearest',
						intersect: false,
						position: 'nearest',
						xPadding: 10,
						yPadding: 10,
						caretPadding: 10
					},
					legend: {
						display: false
					},
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Month'
							}
						}],
						yAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Value'
							},
							ticks: {
								beginAtZero: true
							}
						}]
					},
					elements: {
						line: {
							tension: 0.0000001
						},
						point: {
							radius: 4,
							borderWidth: 12
						}
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 10,
							bottom: 0
						}
					}
				}
			}
		};

		// @ts-ignore
		this.widget4_1 = shuffle([
			{
				pic: './assets/media/files/doc.svg',
				title: 'Metronic Documentation',
				url: 'https://keenthemes.com.my/metronic',
			}, {
				pic: './assets/media/files/jpg.svg',
				title: 'Project Launch Evgent',
				url: 'https://keenthemes.com.my/metronic',
			}, {
				pic: './assets/media/files/pdf.svg',
				title: 'Full Developer Manual For 4.7',
				url: 'https://keenthemes.com.my/metronic',
			}, {
				pic: './assets/media/files/javascript.svg',
				title: 'Make JS Development',
				url: 'https://keenthemes.com.my/metronic',
			}, {
				pic: './assets/media/files/zip.svg',
				title: 'Download Ziped version OF 5.0',
				url: 'https://keenthemes.com.my/metronic',
			}, {
				pic: './assets/media/files/pdf.svg',
				title: 'Finance Report 2016/2017',
				url: 'https://keenthemes.com.my/metronic',
			},
		]);
		// @ts-ignore
		this.widget4_2 = shuffle([
			{
				pic: './assets/media/users/100_4.jpg',
				username: 'Anna Strong',
				desc: 'Visual Designer,Google Inc.',
				url: 'https://keenthemes.com.my/metronic',
				buttonClass: 'btn-label-brand'
			}, {
				pic: './assets/media/users/100_14.jpg',
				username: 'Milano Esco',
				desc: 'Product Designer, Apple Inc.',
				url: 'https://keenthemes.com.my/metronic',
				buttonClass: 'btn-label-warning'
			}, {
				pic: './assets/media/users/100_11.jpg',
				username: 'Nick Bold',
				desc: 'Web Developer, Facebook Inc.',
				url: 'https://keenthemes.com.my/metronic',
				buttonClass: 'btn-label-danger'
			}, {
				pic: './assets/media/users/100_1.jpg',
				username: 'Wilter Delton',
				desc: 'Project Manager, Amazon Inc.',
				url: 'https://keenthemes.com.my/metronic',
				buttonClass: 'btn-label-success'
			}, {
				pic: './assets/media/users/100_5.jpg',
				username: 'Nick Stone',
				desc: 'Visual Designer, Github Inc.',
				url: 'https://keenthemes.com.my/metronic',
				buttonClass: 'btn-label-dark'
			},
		]);
		// @ts-ignore
		this.widget4_3 = shuffle([
			{
				icon: 'flaticon-pie-chart-1 kt-font-info',
				title: 'Metronic v6 has been arrived!',
				url: 'https://keenthemes.com.my/metronic',
				value: '+$500',
				valueColor: 'kt-font-info'
			}, {
				icon: 'flaticon-safe-shield-protection kt-font-success',
				title: 'Metronic community meet-up 2019 in Rome.',
				url: 'https://keenthemes.com.my/metronic',
				value: '+$1260',
				valueColor: 'kt-font-success'
			}, {
				icon: 'flaticon2-line-chart kt-font-danger',
				title: 'Metronic Angular 8 version will be landing soon..',
				url: 'https://keenthemes.com.my/metronic',
				value: '+$1080',
				valueColor: 'kt-font-danger'
			}, {
				icon: 'flaticon2-pie-chart-1 kt-font-primary',
				title: 'ale! Purchase Metronic at 70% off for limited time',
				url: 'https://keenthemes.com.my/metronic',
				value: '70% Off!',
				valueColor: 'kt-font-primary'
			}, {
				icon: 'flaticon2-rocket kt-font-brand',
				title: 'Metronic VueJS version is in progress. Stay tuned!',
				url: 'https://keenthemes.com.my/metronic',
				value: '+134',
				valueColor: 'kt-font-brand'
			}, {
				icon: 'flaticon2-notification kt-font-warning',
				title: 'Black Friday! Purchase Metronic at ever lowest 90% off for limited time',
				url: 'https://keenthemes.com.my/metronic',
				value: '70% Off!',
				valueColor: 'kt-font-warning'
			}, {
				icon: 'flaticon2-file kt-font-focus',
				title: 'Metronic React version is in progress.',
				url: 'https://keenthemes.com.my/metronic',
				value: '+13%',
				valueColor: 'kt-font-focus'
			},
		]);
		// @ts-ignore
		this.widget4_4 = shuffle([
			{
				pic: './assets/media/client-logos/logo5.png',
				title: 'Trump Themes',
				desc: 'Make Metronic Development',
				url: 'https://keenthemes.com.my/metronic',
				value: '+$2500',
				valueColor: 'kt-font-brand'
			}, {
				pic: './assets/media/client-logos/logo4.png',
				title: 'StarBucks',
				desc: 'Good Coffee & Snacks',
				url: 'https://keenthemes.com.my/metronic',
				value: '-$290',
				valueColor: 'kt-font-brand'
			}, {
				pic: './assets/media/client-logos/logo3.png',
				title: 'Phyton',
				desc: 'A Programming Language',
				url: 'https://keenthemes.com.my/metronic',
				value: '+$17',
				valueColor: 'kt-font-brand'
			}, {
				pic: './assets/media/client-logos/logo2.png',
				title: 'GreenMakers',
				desc: 'Make Green Development',
				url: 'https://keenthemes.com.my/metronic',
				value: '-$2.50',
				valueColor: 'kt-font-brand'
			}, {
				pic: './assets/media/client-logos/logo1.png',
				title: 'FlyThemes',
				desc: 'A Let\'s Fly Fast Again Language',
				url: 'https://keenthemes.com.my/metronic',
				value: '+200',
				valueColor: 'kt-font-brand'
			},
		]);
	}

	public onViewPlan() {
		alert('TODO');
	}

	private async loadNotifications() {
		const result = await this.api.get_notifications(this.notificationsPage, this.search);
		this.notifications = result.notifications;
		this.totalNotifications = result.total;

		if (this.user.getUser().enable_batch_notifications != '1') {
			this.notifications = this.notifications.filter(notification => notification.contato_id && notification.notificacao_id);
		}

		this.notifications.map(notification => {
			notification.hasUnread = notification.mensagens_recebidas.find(e => !e.lida || e.lida == 0);
			return notification;
		});

		this.contacts = this.notifications.filter(e => e.contato).map(e => e.contato);
		this.contacts = this.contacts.filter((v, i, a) => a.findIndex(t => (t.whatsapp === v.whatsapp)) === i); // Unique contacts
		this.ref.detectChanges();
	}

	onNotificationFilter(value) {
		this.checkedFilter = value;
		this.updateNotificationsChart(value);
	}

	private updateNotificationsChart(value) {
		this.chartOptionsCommunications = {
			config: {
				type: 'line',
				data: {
					labels: this.user.getDashboardData().dashboard.notifications.filter(e => value === 0 || e.notificacao_id == value).map(e => e.date),
					datasets: [{
						label: 'Envio de Notificação',
						backgroundColor: 'rgb(156, 214, 254)', //Chart.helpers.color('#e14c86').alpha(1).rgbString(),  //gradient
						borderColor: '#D2EDFF',

						pointBackgroundColor: 'rgba(0, 0, 0, 0)', //Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: 'rgba(0, 0, 0, 0)', // Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.light'),
						pointHoverBorderColor: 'rgba(255, 255, 255, 0.1)', // Chart.helpers.color('#ffffff').alpha(0.1).rgbString(),

						//fill: 'start',
						data: this.user.getDashboardData().dashboard.notifications.filter(e => value === 0 || e.notificacao_id == value).map(e => e.quantity)
					}]
				},
				options: {
					title: {
						display: false,
					},
					tooltips: {
						mode: 'nearest',
						intersect: false,
						position: 'nearest',
						xPadding: 10,
						yPadding: 10,
						caretPadding: 10
					},
					legend: {
						display: false
					},
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Month'
							}
						}],
						yAxes: [{
							display: false,
							gridLines: false,
							scaleLabel: {
								display: true,
								labelString: 'Value'
							},
							ticks: {
								beginAtZero: true
							}
						}]
					},
					elements: {
						line: {
							tension: 0.0000001
						},
						point: {
							radius: 4,
							borderWidth: 12
						}
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 10,
							bottom: 0
						}
					}
				}
			}
		};
		this.ref.detectChanges();

		console.log(this.notificationsChart);
	}

	onViewNotification(notification) {
		this.activeNotification = notification;
		this.activeNotification.messages = [...[notification], ...notification.mensagens_recebidas, ...notification.mensagens_enviadas];
		this.uploads = [];
		this.attachContacts = [];
		this.reply.message = '';

		this.modalService.open(this.modalViewNotification, {size: 'sm'});
		this.api.mark_as_read(notification);
	}

	onUpload(event, type) {
		console.log(event);

		const fileReader = new FileReader();
		fileReader.onload = (e) => {
			if (e.total >= 16000000) {
				this.notify.show('O tamanho máximo permitido para envios é de 16 MB.');
				return;
			}

			console.log(e);
			const file = {
				data: (e as any).target.result,
				// caption:  this.tools.extractFilename(event.target.value),
				name: event.target.files[0].name,
				// preview: null,
				// icon: null,
				type
			};

			// switch (type) {
			// 	case 'image': {
			// 		file.preview = file.image;
			// 		break;
			// 	}
			// 	case 'video': {
			// 		file.icon = 'la la-file-video-o fa-3x';
			// 		break;
			// 	}
			// 	case 'file': {
			// 		file.icon = 'la la-file-o fa-3x';
			// 		break;
			// 	}
			// }

			this.uploads.push(file);
			this.ref.markForCheck();
		};
		fileReader.readAsDataURL(event.target.files[0]);
	}

	onAttachContacts() {
		this.modalService.open(this.modalContacts, {size: 'sm'});
	}

	onContactSearch() {

	}

	onContactCheckboxChange($event: any, contact: any) {
		if ($event.srcElement.checked) {
			this.attachContacts.push(contact);
		} else {
			this.attachContacts = this.attachContacts.filter(item => item.whatsapp !== contact.whatsapp);
		}

		console.log(this.attachContacts);
	}

	async onSendReply() {
		this.sendingReply = true;

		for (const contact of this.attachContacts) {
			await this.api.send_contact(this.activeNotification.contato.whatsapp, contact.whatsapp);
		}

		for (const upload of this.uploads) {
			await this.api.send_file(this.activeNotification.contato.whatsapp, upload.data, upload.name, upload.name);
		}

		await this.api.send_message(this.activeNotification.contato.whatsapp, this.reply.message, this.activeNotification.id).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.reply.message = '';

			this.attachContacts = [];
			this.uploads = [];

			this.sendingReply = false;
			this.ref.detectChanges();
		});
	}

	onRemoveUploadItem(item: any) {
		this.uploads = this.uploads.filter(e => e !== item);
	}

	onRemoveContactItem(item: any) {
		this.attachContacts = this.attachContacts.filter(e => e !== item);
	}

	getNotifications($event) {
		this.notificationsPage = $event;
		this.loadNotifications();
	}

	doSearch() {
		this.loadNotifications();
	}
}
