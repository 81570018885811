// Angular
import {ChangeDetectorRef, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {ToolsService} from '../../../../services/tools.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../../services/user.service';
import {KtDialogService} from '../../../../core/_base/layout';
import {ApiService} from '../../../../services/api.service';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
	@ViewChild('modalPlanExtract', {static: false}) modalPlanExtract: ElementRef;
	filter: any = {
		start_date: '',
		end_date: ''
	};
	private planExtractData: any = [];

	constructor(public tools: ToolsService,
				private modalService: NgbModal,
				public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public ref: ChangeDetectorRef) {
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
	}

	url = ''

	// onViewPlanExtract() {
	// 	this.loadPlanExtract();
	// 	this.modalService.open(this.modalPlanExtract, {size: 'lg'});
	// }

	// private async loadPlanExtract() {
	// 	this.ktDialogService.show();
	// 	this.planExtractData = await this.api.get_plan_extract();
	// 	this.ktDialogService.hide();
	// 	this.ref.detectChanges();
	// }

	isTarifando(){
		if(this.url=='tarifando'){
			return true
		}
	}

	isStandard(){
		if(this.url=='painel' || window.location.href.includes('localhost')){
			return true
		}
	}
}
