// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import {AuthNoticeService, AuthService, Login} from '../../../../core/auth';
import {ApiService} from '../../../../services/api.service';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private api: ApiService
	) {
		this.unsubscribe = new Subject();
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url.name = url
		this.isStandard()
	}

	url = {name:'',site:''}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */
	email: any = '';
	year: number;

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
		this.setYear();
	}

	setYear(){
		this.year = new Date().getFullYear();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const email = this.email;
		if (!email) {
			this.authNoticeService.setNotice('Por favor, preencha todos os campos corretamente.', 'danger');
			return;
		}
		
		this.authNoticeService.setNotice('', '');
		this.loading = true;
		this.api.request_password(email).then(result => {
			if (result.success) {
				this.authNoticeService.setNotice(result.message, 'success');
				this.router.navigateByUrl('/auth/login');
			} else {
				this.authNoticeService.setNotice(result.message, 'danger');
			}
		}).catch(reason => {
			this.authNoticeService.setNotice(reason.message, 'danger');
			this.loading = false;
			this.cdr.markForCheck();
			console.log(reason)
		}).finally(() => {
			this.loading = false;
			this.cdr.markForCheck();;
		});
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	isStandard(){
		if(this.url.name=='painel' || this.url.name=='notifiqueai' || window.location.href.includes('localhost')){
			this.url.name='Aida Digital'
			this.url.site='https://www.aida.digital'
			return true
		}else{
			this.url.name = this.url.name.toLocaleUpperCase()
		}
	}
}
